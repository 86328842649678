class ResourceTypeValidation {
  constructor(validRolesByTypeName) {
    this.validRolesByTypeName = validRolesByTypeName;
  }

  getRoleName() {
    return $('#user_role_role_id').find('option:selected').text();
  }

  getValidResourceTypes() {
    return this.validRolesByTypeName[this.getRoleName()];
  }

  isValidType(type, validResourceTypes) {
    if (validResourceTypes === undefined) {
      return false;
    }
    return !validResourceTypes.includes(type);
  }

  updateAvailableResourceTypesOnAddRole() {
    let validResourceTypes = this.getValidResourceTypes();
    let selectedOption;

    document.querySelectorAll('.form-group.resource_type input').forEach(option => {
      let disabled = this.isValidType(option.value, validResourceTypes);
      option.disabled = disabled;
      option.closest('label').classList.toggle('disabled', disabled);
      if (option.closest('label').classList.contains('active')) {
        selectedOption = option.value;
      }
    });

    if (this.isValidType(selectedOption, validResourceTypes)) {
      new Telescope($('#add-panel')).val('none');
    }
  }

  updateAvailableResourceTypesOnEditRole() {
    let validResourceTypes = this.getValidResourceTypes();
    let resourceTypeSelector = document.querySelector('#user_role_resource_type');
    let selectedOption = resourceTypeSelector.querySelector(`option[value='${resourceTypeSelector.value}']`);

    resourceTypeSelector.querySelectorAll('option').forEach(option => {
      option.disabled = this.isValidType(option.value, validResourceTypes);
    });

    if (!selectedOption || selectedOption.disabled) {
      resourceTypeSelector.value = null;
    }
  }

  static async setup() {
    let validRolesByTypeName = await fetch(`${window.location.origin}/admin/users/valid_resource_types`)
      .then(response => response.json());

    let validation = new ResourceTypeValidation(validRolesByTypeName);
    if (document.querySelector('#add-role-for-user-heading')) {
      document.querySelector('#add-role-for-user-heading').dataset.loadedRoles = true;
      $('#user_role_role_id').on('change', () => { // cannot use addEventListener on select2
        validation.updateAvailableResourceTypesOnAddRole();
      });
      validation.updateAvailableResourceTypesOnAddRole();
    } else if (document.querySelector('#edit-user-role-heading')) {
      document.querySelector('#edit-user-role-heading').dataset.loadedRoles = true;
      document.querySelector('#user_role_role_id').addEventListener('change', () => {
        validation.updateAvailableResourceTypesOnEditRole();
      });
      validation.updateAvailableResourceTypesOnEditRole();
    }
  }
}

export var adminUserRoleResourceTypeValidation = {
  setup() {
    ResourceTypeValidation.setup();
  }
};
