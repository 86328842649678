import BusyBody from "../../services/busy_body";

class LinksEditor {
  constructor(holder = document) {
    this.holder = holder;
  }

  static closest(el) {
    return new LinksEditor(el.closest('.js-links'));
  }

  add() {
    let template = this._template();
    let linksToAdd = template.copy({reindex: {
      index: this._links().length,
      groupName: 'information_links',
      groupBy: '.link'
    }});
    linksToAdd.classList.remove('hidden');

    this.holder.querySelector('.links').append(linksToAdd);
  }

  addDefault() {
    let destroyLinkEls = this.holder.querySelectorAll('.link:not(.template) .destroy-link');
    if (Array.from(destroyLinkEls).every(destroyLinkEl => destroyLinkEl.value === '1')) {
      this.add();
    }
  }

  _links() {
    return this.holder.querySelectorAll('.link:not(.template)');
  }

  _template() {
    let templateEl = this.holder.querySelector('.link.template');
    return new Template(templateEl);
  }
}

export var recruitmentLinksEditor = {
  setup() {
    const contactInfoModalSelector = '#contact-information';

    new BusyBody({
      selector: contactInfoModalSelector,
      added: () => this.eventListenerOn(
        'click',
        document.querySelector(contactInfoModalSelector),
        '.add-link',
        e => this.addLink(e)
      )
    });

    new BusyBody({
      selector: contactInfoModalSelector,
      added: () => this.eventListenerOn(
        'click',
        document.querySelector(contactInfoModalSelector),
        '.remove-link',
        e => this.removeLink(e)
      )
    });
  },

  addLink(e) {
    e.preventDefault();
    LinksEditor.closest(e.target).add();
  },

  removeLink(e) {
    e.preventDefault();
    let doomedLink = e.target.closest('.link');
    doomedLink.style.display = 'none';
    doomedLink.querySelector('.destroy-link').value = '1';
    LinksEditor.closest(e.target).addDefault();
  },

  eventListenerOn(event, baseEl, selector, callback) {
    baseEl.addEventListener(event, (e) => {
      let closest = e.target.closest(selector);
      if (closest && baseEl.contains(closest)) {
        callback(e);
      }
    });
  }
}
