/* This jQuery extension will allow you to easily inject text into a textarea wherever
 * the caret/cursor is currently at.
 *
 * Usage:
 * <textarea id="messageBody"></textarea>
 *
 * <a href onclick="$('#messageBody').insertAtCaret('this text is inserted at cursor');">Insert text</a>
 *
 * Borrowed from various sources on the web.
 */

jQuery.fn.extend({
  insertAtCaret: function(myValue){
    return this.each(function(i) {
      if (document.selection) {
        //For browsers like Internet Explorer
        this.focus();
        var sel = document.selection.createRange();
        sel.text = myValue;
        this.focus();
      } else if (this.selectionStart || this.selectionStart === 0) {
        //For browsers like Firefox and Webkit based
        var startPos = this.selectionStart;
        var endPos = this.selectionEnd;
        var scrollTop = this.scrollTop;
        this.value = this.value.substring(0, startPos)+myValue+this.value.substring(endPos,this.value.length);
        this.focus();
        this.selectionStart = startPos + myValue.length;
        this.selectionEnd = startPos + myValue.length;
        this.scrollTop = scrollTop;
      } else {
        this.value += myValue;
        this.focus();
      }
      this.dispatchEvent(new Event("input"));
      this.dispatchEvent(new Event("change"));
    });
  }
});

export default {
  setup: function() {
    $(document).on('click', '[data-handle]', function(event) {
      event.preventDefault();

      let $this = $(event.target);
      $($(this).data('target')).insertAtCaret($(this).data('handle'));
    });
  }
};




