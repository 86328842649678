export default class DisciplinesDescription {
  constructor(picker) {
    this.picker = picker;
    this.description = picker.closest('form').querySelector('.form-group.disciplines-description');
  }
   
  watch() {
    $(this.picker).on('change', (e) => {
      let other = $(this.picker).select2('data').find(d => d.code?.match(/99$/));
      let notApplicable = $(this.picker).select2('data').find(d => d.text?.match(/Not applicable/));
      let noDescription = (other === undefined && notApplicable === undefined);

      this.description.classList.toggle('hidden', noDescription);
      this.description.disabled = noDescription;
    });
  }
  
  
  static setup() {
    new BusyBody({
      selector: '.discipline-picker',
      added: (picker) => new DisciplinesDescription(picker).watch(),
    });
  }
}
