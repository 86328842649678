import {Picker} from 'pickers/picker';

export default class AcademicYearRangePicker extends Picker {
  templateSelection(object) {
    return object.text || "Please make a selection";
  }
  templateResult(object) {
    let years = object.element?.dataset?.years;
    if (years) {
      return Components.tag("div", object.text, Components.tag("div", years, {class: "metadata"}));
    }

    return object.text;
  }
  
  static setup() {
    AcademicYearRangePicker.watch('select.academic-year-range-picker');
  }
};
