export var applicationDocumentViewer = {
    setup() {
        this.ariaSelectedListener();
    },

    ariaSelectedListener() {
        $('#application-files li.active a[data-toggle="tab"]').attr('aria-selected', true);
        $('#application-files a[data-toggle="tab"]').on('shown.bs.tab', (tab) => {
            $(tab.target).attr('aria-selected', true);
            $(tab.relatedTarget).attr('aria-selected', false);
        })
    }
}
