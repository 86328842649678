import BusyBody from "../../services/busy_body";

export let formVeiledForm = {
  setup() {
    new BusyBody({
      selector: '.veiled-form',
      added: (detailsEl) => this.setupFormSubmitButton(detailsEl)
    });
  },

  setupFormSubmitButton(detailsEl) {
    const modal = detailsEl.closest('.modal');
    const submitButton = modal.querySelector('.modal-footer .btn-primary');
    if (submitButton && !submitButton.getAttribute('data-dismiss')) {
      if (!detailsEl.open) submitButton.disabled = true;
      detailsEl.addEventListener('toggle', (e) => submitButton.disabled = !detailsEl.open);
    }
  }
}
