import { Components } from "../../components/components";

const notYetAssignedValue = "Not yet assigned";

export default class PendingEmployeeID {
  constructor(textInput) {
    this.textInput = textInput;

    this.#setupTextInput();
    this.#addPendingCheckbox();
    this.#setupFormGroup();
  }

  get notYetAssigned() { return this.textInput.value == notYetAssignedValue }
  get formGroup() { return this.textInput.closest('.form-group') }

  #setupTextInput() {
    this.textInput.placeholder = "UC Path Employee ID";
    this.textInput.dataset.placement = "right";
    this.textInput.inputmode = "numeric";
    this.textInput.pattern = "\d{8}";
    $(this.textInput).tooltip({
      title: "Numbers only. If you don't know the employee ID, click \"Not yet assigned\".",
      trigger: "manual",
      container: "body",
    })

    this.textInput.addEventListener("input", (event) => {
      let numbersOnly = this.textInput.value.replace(/[^0-9]/g, "");
      let changed = this.textInput.value != numbersOnly;
      this.textInput.value = numbersOnly;
      this.oldValue = numbersOnly;
      this.pendingCheckbox.checked = false;
      $(this.textInput).tooltip(changed ? "show" : "hide");
    });

    this.textInput.addEventListener("blur", (event) => $(this.textInput).tooltip("hide"))
  }

  #addPendingCheckbox() {
    this.textInput.after(this.#makePendingLabel());
  }

  #makePendingCheckbox() {
    return Components.tag(
      "input",
      {type: "checkbox", class: "pending-employee-id--toggle"},
      (checkbox) => { checkbox.disabled = this.textInput.disabled },
      (checkbox) => checkbox.addEventListener("change", (event) => {
        if (checkbox.checked) { this.oldValue = this.notYetAssigned ? "" : this.textInput.value; }
        this.textInput.value = checkbox.checked ? notYetAssignedValue : (this.oldValue ?? this.textInput.value);
      }),
      (checkbox) => {
        checkbox.checked = this.notYetAssigned;
        checkbox.dispatchEvent(new Event("change"));
      }
    );
  }

  #makePendingLabel() {
    this.pendingCheckbox = this.#makePendingCheckbox();

    return Components.tag(
      "label",
      {class: "pending-employee-id--label text-normal"},
      this.pendingCheckbox,
      " Not yet assigned. ",
      Components.tag("span", "Remind me later.", {class: "text-muted"})
    );
  }

  #setupFormGroup() {
    this.formGroup.classList.add("pending-employee-id");
  }
  
  static setup() {
    new BusyBody({
      selector: 'input[data-pending-employee-id]',
      added: (textInput) => new PendingEmployeeID(textInput),
    });
  }
}
