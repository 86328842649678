import Reorder from 'behavior/reorderable';

class LevelReorder extends Reorder {
  constructor(selector) {
    super(selector);
  }
  
  sortableOptions() {
    return {
      ...super.sortableOptions(),
      axis: "y",
      items: '.fieldset.level'
    };
  }
  
  updateOrder() {
    this.updateOrderFields();
  }
}

export var recruitmentLevelReordering = new LevelReorder('fieldset.levels');
