export var uiHelpdocs = {
  createSidebar: function() {
    if ($('.article').length) {
      $('aside.sidebar').prepend("<nav id='nav-secondary'><ul class='quick-links'></ul></nav>");

      $('.article').each(function() {
        var title = $(this).children(":first").text();
        var id = $(this).attr('id');
        var previousElement = $(this).prev();

        if (previousElement.hasClass('section')) {
          var header = "<li class='section-header'>" + previousElement.text() + "</li>";
          $('.quick-links').append(header);
        }

        var link = "<li><a href='#" + id + "'>" + title + "</a></li>";
        $('.quick-links').append(link);
      });
    }
  },
  
  selectArticle: function(requestedArticle) {
    $('.quick-links li a[href$="' + requestedArticle + '"]').parent().addClass('active');
    setTimeout(function() { // setTimeout fixes http://stackoverflow.com/questions/5584500/ordered-list-showing-all-zeros-in-ie9/
      $(requestedArticle).show();
    }, 1);
  },
  
  initialLoad: function(menu, articles, allContent) {
    allContent.hide();
    if (window.opener === null) { $(".help_home_link").show(); }
    
    if ($(window.location.hash).length) {
      uiHelpdocs.selectArticle(window.location.hash);
    } else {
      menu.first().addClass('active');
      articles.first().show();
    }
  },
  
  addArticleSelectionListener: function(menu, allContent) {
    $('.quick-links li a, .article:not(.skip-help-navigation) a').click(function(event) {
      if (this.hash.length > 0 && event.metaKey === false) {
        menu.removeClass('active');
        allContent.hide();
        uiHelpdocs.selectArticle(this.hash);
      }
    });
  },
  
  disableAnchorScrolling: function() {
    $('.quick-links li a').click(function(event) {
      event.preventDefault();
      history.replaceState(null, '', this.hash);
    });
  },
  
  addViewAllListener: function(menu, allContent) {
    // View all button
    $('<input id="viewAll" type="button" value="View All">').appendTo('#nav-secondary');
    $('#viewAll').click(function() {
      menu.addClass('active');
      allContent.show();
    });
  },
  
  setup: function() {
    uiHelpdocs.createSidebar()

    let menu = $('.quick-links li');
    let articles = $('.article');
    let allContent = $('.article, .help_content h3');

    uiHelpdocs.initialLoad(menu, articles, allContent);
    uiHelpdocs.addArticleSelectionListener(menu, allContent);
    uiHelpdocs.disableAnchorScrolling();
    uiHelpdocs.addViewAllListener(menu, allContent);
  }
};
