import Reorder from 'behavior/reorderable';

class LinkReorder extends Reorder {
  constructor(selector) {
    super(selector);
  }

  sortableOptions() {
    return {
      ...super.sortableOptions(),
      axis: "y",
      items: '.fieldset.link'
    };
  }

  updateOrder() {
    this.updateOrderFields(0);
  }
}

export var recruitmentLinkReordering = new LinkReorder('fieldset.links');
