import Appointments from '../../components/appointments';
import BusyBody from '../../services/busy_body';

export let appointmentsEditor = {
  setup() {
    const appointmentsEditorSelector = '.js-appointments';

    new BusyBody({
      selector: appointmentsEditorSelector,
      added: el => {
        el.addEventListener('click', this.addAppointmentListener);
        el.addEventListener('click', this.makeEditableListener);
      }
    });
  },

  addAppointment(e) {
    e.preventDefault();
    Appointments.closest(e.target).add();
  },

  makeEditable(e) {
    e.preventDefault();
    Appointments.closest(e.target).setEditable(true).ensureAtLeastOneAppointment();
  },

  addAppointmentListener(e) {
    let closest = e.target.closest('.add-appointment');
    if (closest && this.contains(closest)) {
      appointmentsEditor.addAppointment(e);
    }
  },

  makeEditableListener(e) {
    let closest = e.target.closest('.offer-editor .make-editable');
    if (closest && this.contains(closest)) {
      appointmentsEditor.makeEditable(e);
    }
  }
}
