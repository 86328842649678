class BulkUpdateVeilModal {
  constructor(modal) {
    this.modal = modal;
  }

  get couldNotSaveAlert() {
    return this.modal.querySelector('.alert.could-not-save');
  }

  get preflightLink() {
    return this.modal.querySelector('a.update-veil--bulk-preflight-trigger');
  }

  get preflight() {
    return this.modal.querySelector('.update-veil--bulk-preflight');
  }

  get form() {
    return this.modal.closest('form');
  }

  get applicationIds() {
    return $(this.form).selectedCollector().values;
  }

  fetchPreflight() {
    let link = this.preflightLink;
    link.dataset.params = new URLSearchParams({selected: this.applicationIds.join(',')}).toString();
    link.click();
  }

  resetPreflight() {
    this.preflightLink.classList.remove('hidden');
    this.preflight.classList.add('hidden');
  }
}

export var applicationBulkUpdateVeilModal = {
  setup: function() {
    $(document).on('show.bs.modal', '.update-veil--bulk', event => {
      let modal = new BulkUpdateVeilModal(event.target);
      modal.couldNotSaveAlert.classList.add('starts-hidden');
      modal.fetchPreflight();
    }).on('hidden.bs.modal', '.update-veil--bulk', event => {
      let modal = new BulkUpdateVeilModal(event.target);
      modal.resetPreflight();
    }).on("ajax:success", "#bulk-update-veil-form", event => {
      if (event.target.matches("#bulk-update-veil-form")) {
        if (event.detail && event.detail[0]?.error_message) { return; }

        let modal = new BulkUpdateVeilModal(event.target);
        TableTyrant.Grid.withID("applications").updateRows(modal.applicationIds);
      }
    });
  }
};
