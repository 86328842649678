import BusyBody from 'services/busy_body';
import {Components} from 'components/components';

export default class Reorder {
  constructor(selector) {
    this._selector = selector;
  }
  
  el() {
    return $(this._selector);
  }
  
  get url() {
    return null;
  }
  
  get data() {
    throw "Define in subclass";
  }
  
  // hooks; can be handled in subclass
  sortStarted() {}
  sortStopped() {}
  updateStarted() {}
  updateDone() {}
  updateSuccess(_json, _status, _xhr) {}
  updateFailed() {
    alert('Could not save order');
  }
  
  updateOrder() {
    this.updateOrderFields();
    this.updateOrderViaAjax();
  }
  
  updateOrderViaAjax() {
    if (!this.url) return;
    
    this.updateStarted();
    
    $.ajax({
      url: this.url,
      method: 'PUT',
      data: this.data
    })
    .always(() => this.updateDone())
    .then((_json, _status, _xhr) => this.updateSuccess(_json, _status, _xhr))
    .fail(() => this.updateFailed());
  }
  
  updateOrderFields(startingIndex = 1) {
    this.updateStarted();
    
    var listOrder = startingIndex;
    this.orderFields().each(function() {
      $(this).val(listOrder++);
    });
    
    this.updateDone();
  }
  
  orderFields() {
    return this.el().find('.js-reorderable-order-field');
  }
  
  makeSortable() {
    this.el().sortable(this.sortableOptions());
  }
  
  sortableOptions() {
    return {
      cursor: 'move',
      handle: '.grip',
      opacity: .75,
      placeholder: 'sorting-placeholder',
      start: (e, ui) => this.sortStarted(e, ui),
      stop: (e, ui) => this.sortStopped(e, ui),
      update: () => this.updateOrder()
    };
  }
  
  setup() {
    new BusyBody({selector: this._selector, added: () => this.makeSortable()});
    new BusyBody({
      selector: [this._selector, this.sortableOptions.selector || '> *'].join(' '),
      added: () => this.updateOrderFields(),
      callNow: false,
    },);
    
    this.addMoveLinks();
  }

  addMoveLinks() {
    this.el().find('.grip').each((ix, grip) => {
      $('<div>')
        .css({display: 'flex', flexDirection: 'column'})
        .append(this.moveLink('chevron-up', 'Move up', 'prev', 'Before'))
        .append(this.moveLink('chevron-down', 'Move down', 'next', 'After'))
        .insertBefore(grip);
    });
  }
  
  moveLink(iconName, text, jump, beforeOrAfter) {
    return Components.iconicLink(iconName, text,
      {class: 'sr-only', style: 'white-space: nowrap; margin-right: .5em'},
      link => link.addEventListener('click', (event) => {
        event.preventDefault();
        
        var $link = $(event.target);
        var $row = $link.closest('tr');
        var $jump = $row[jump]('tr:not(.header)');
        $row[`insert${beforeOrAfter}`]($jump);
        $link.focus();
        
        this.updateOrder();
      }),
    );
  }
}
