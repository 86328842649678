import {StagingGrid} from 'cosmopolitan/staging_grid/staging_grid';
import {TableTyrantFilters} from 'cosmopolitan/table_tyrant/filters';
import {TableTyrantFilterGroups} from 'cosmopolitan/table_tyrant/filter_groups';
import {TableTyrantGrid} from 'cosmopolitan/table_tyrant/grid';
import {TableTyrantSorting} from 'cosmopolitan/table_tyrant/sorting';
import {TableTyrantWorkspaces} from 'cosmopolitan/table_tyrant/workspaces';

// classes
export default class TableTyrant {
  constructor(el) {
    this.el = el;
  }
  
  get #tyrantEls() { return $(this.el) }
  
  setup() {
    if (this.#tyrantEls.is('.table-tyrant-setup')) { return }
    this.#tyrantEls.addClass('table-tyrant-setup');
    
    this.#setupDynamicFilteringEvents();
    this.#setupDefaultFields();
    
    this.#setupUrlUpdating();
    this.#setupErrorHandling();
    
    this.#setupWorkspacePopover();
    this.#setupWorkspaceSwitchingEvents();
    this.#setupRibbonEvents();
    this.#setupRibbonFilterEvents();
    
    this.#setupFiltersPanel();
    
    this.#setupFilterGroupEvents();
    this.#setupFilterEvents();
    
    this.#setupRefreshAnimation();
    this.#setupRowRefresh();
    this.#setupSelectAll();
    this.#setupRestriping();
    
    this.#setupPagerEvents();
  }
  
  #setupDefaultFields() {
    let defaultFieldValues = {
      'page': /^1?$/,
      'per-page': /^$/,
      'sort': /^$/,
      'q': /^$/
    };
    
    this.#tyrantEls.on('submit', 'form.edit-workspace', event => {
      for (let [inputName, defaultPattern] of Object.entries(defaultFieldValues)) {
        this.el.querySelectorAll(`input[name="${inputName}"]`).forEach(input => {
          input.disabled = !!input.value.match(defaultPattern);
        });
      }
    });
  }
  
  #setupWorkspacePopover() {
    this.#tyrantEls.each(function() {
      TableTyrant.Grid.closest($(this)).workspaces.setupPopover();
    });

    this.#tyrantEls.on('confirm', '.js-delete-workspace', function(event) {
      TableTyrant.Workspaces.closest($(event.target)).hidePopover();
    });
    this.#tyrantEls.on('confirm:complete', '.js-delete-workspace', function(event) {
    });
    this.#tyrantEls.on('ajax:success', '.js-delete-workspace', function(event) {
      var $li = $(event.target).closest('li');
      var workspaces = TableTyrant.Workspaces.closest($li);
    
      if ($li.find('a.is-current').length > 0) {
        workspaces.linkForDefaultWorkspace.click();
      }
    
      $li.remove();
      workspaces.checkForZeroState();
    });
    this.#tyrantEls.on('ajax:error', '.js-delete-workspace', function(event) {
      alert('Could not delete workspace.');
    });
    this.#tyrantEls.on('keyup', '#workspace_name', function(event) {
      TableTyrant.Workspaces.updateActions($(event.target));
    });
    TableTyrant.Workspaces.updateActions($('#workspace_name'));
    TableTyrant.Workspaces.checkForZeroState();
  
    this.#tyrantEls.on('ajax:success', '.workspace-meta', function(event) {
      let json = event.detail[0];
      var $form = $(event.target);
      TableTyrant.Grid.closest($form).workspaces.workspaceHasChanged(
        $form.find('#workspace_name').val(),
        json.workspaces || []);
      $form
        .attr('action', json.meta.current_workspace_url)
        .attr('method', json.meta.current_workspace_url ? 'put' : 'post');
    });
    
    this.#tyrantEls.on('ajax:error', '.workspace-meta', function(event) {
      let errorMessage = event.detail[0]?.error_message || "Could not save workspace.";
      alert(errorMessage);
    });
  }
  
  #setupRibbonEvents() {
    this.#tyrantEls.on('click', '.workspace-ribbon', function(event) {
      if (event.altKey && event.metaKey) {
        TableTyrant.Grid.closest(event.target).showDiagnostics();
      }
    });
  }
  
  #setupRibbonFilterEvents() {
    this.#tyrantEls.find('.filter-summary[data-filter-group-name]').each(function() {
      var $summary = $(this);
      var $gridEl = $summary.closest('.table-tyrant');
      var grid = new TableTyrant.Grid($gridEl);
      var name = $summary.data('filter-group-name');
      var $groupEl = $gridEl.find('.workspace-editor-modal .filter-list .filter-group[data-filter-group-name="' + name + '"]');
      var $groupElBookmark = $('<div/>').addClass('filter-group-placeholder')
        .insertAfter($groupEl)
        .data('tt.filter-group', $groupEl);

      var restoreGroupEl = function() {
        $groupElBookmark.before($groupEl);
      };
    
      $summary.popover({
        placement: 'bottom',
        trigger: 'hover',
        html: true,
        content: function() {
          var $fieldset = $('<fieldset/>');
          $fieldset[0].addEventListener('autosubmit.picker', event => {
            queueMicrotask(() => $fieldset.find('.update-filter-group').click())
          });
          $summary.one('hidden.bs.popover', function() {
            restoreGroupEl();
          });
          $fieldset.on('change', '.filter-group.tabs .btn-group input[type=radio]', function() {
            $fieldset.find('.update-filter-group').click();
          });
        
          grid.filters.group(name).engaged = true;
          return $fieldset.append($groupEl).append(
            $('<div class="actions">')
              .append($("<button class='btn-primary btn-xs update-filter-group'>Update</button>")
                .on('click', function() {
                  grid.event('filter-group-updated', '-' + name);
                  $summary.data('bs.popover').$tip.addClass('hidden');
                  restoreGroupEl();
                  grid.updateWorkspace();
                })
              )
              .append($("<button class='btn-default btn-xs reset-filter-group'>Remove</button>")
                .text($groupEl.is('.anchored') ? 'Reset' : 'Remove')
                .on('click', function() {
                  $(this).closest('.popover').hide();
                  grid.filters.group(name).engaged = false;
                  grid.updateWorkspace();
                })
              )
          );
        },
        delay: {show: 350, hide: 350}
      });
    });
  }
  
  #setupFiltersPanel() {
    this.#tyrantEls.on('keyup click change', '.js-filter-search', function(event) {
      var grid = TableTyrant.Grid.closest(event.target);
      grid.filters.search($(this).val().toLowerCase());
    });
    
    this.#tyrantEls.on('click', '.filter-group .js-toggle-engagement', function(e) {
      if ($(e.target).closest('.popover').length) {
        $(e.target).closest('.popover').hide();
      } else {
        e.stopPropagation();
        e.preventDefault();

        var group = new TableTyrant.Filters.Group.closest(this);
        group.engaged = !group.engaged;
      }
    });
  }
  
  #setupFilterGroupEvents() {
    this.#tyrantEls.on('click', '.toggle-all', function(event) {
      event.preventDefault();
      new TableTyrant.Filters.Group.closest(event.target).toggleAll();
    });
    this.#tyrantEls.on('change', 'input:checkbox', function(event) {
      new TableTyrant.Filters.Group.closest(event.target).updateAllState();
    });
  }
  
  #setupFilterEvents() {
    this.el.addEventListener('autosubmit.picker', event => {
      let modal = event.target.closest('.workspace-editor-modal');
      if (modal) { event.preventDefault() } // inside the modal, don't autosubmit
    });

    // revert to default filters
    this.#tyrantEls.on('click', '.js-filter-reset', function(e) {
      e.preventDefault();
      TableTyrant.Grid.closest(e.target).event('filter-reset').filters.reset();
    });
  }
  
  #setupWorkspaceSwitchingEvents() {
    this.#tyrantEls.on('click', '.js-workspace-link', function(e) {
      e.preventDefault();
      TableTyrant.Grid.closest(e.target).updateWorkspaceWith({url: e.target.dataset.href || e.href});
    });
  
    this.#tyrantEls.on('click', '.edit-workspace .save', function(e) {
      TableTyrant.Grid.closest(e.target).event('filters-set');
    });
  }
  
  #setupRestriping() {
    this.#tyrantEls.on('restripe.recruit.table-tyrant', function(e) {
      var $gridEl = $(this).closest('.table-tyrant');
    
      if ($gridEl.find(".more-pages-to-see").length || $gridEl.find('.matches tr.filtered-in').length === 0) {
        $gridEl.find('.matches tr.match:nth-child(odd)').addClass('filter-odd');
      } else {
        $gridEl.find('.matches').each(function(ixBody, body) {
          // recolor the alternating rows
          $(body).find('tr.odd, tr.filter-odd').removeClass('filter-odd').removeClass('odd');
          $(body).find('tr.filtered-in').filter(function(ix) {return ix % 2 === 0}).addClass('filter-odd');
        })
      }
    });
  
    this.#tyrantEls.trigger('restripe.recruit.table-tyrant'); // no filter includes? we still want to zebra-stripe
  }
  
  #setupDynamicFilteringEvents() {
    var serverSideSearch = this.#tyrantEls.attr('data-q') || "";
    var searchOnServer = serverSideSearch.length > 0 || this.#tyrantEls.find('.more-to-see, .more-pages-to-see').length > 0;
    
    this.#tyrantEls.each(function() {
      var $gridEl = $(this).closest('.table-tyrant');
      var grid = new TableTyrant.Grid($gridEl);
      
      var include = $(`[data-table-tyrant-finder-for="${grid.id}"]`);
      var dynamicFilters = include.find("input.dynamic-filter");
      var showingRows = function() {
        return $gridEl.find('.matches tr.match:not(.filtered-out)');
      }

      include.off();
      include.submit(function(e) {
        e.preventDefault();

        if (searchOnServer) {
          grid.event('search');
          grid.updateWorkspace();
        }
      });

      var tableRows = $gridEl.find('.matches tr.match');

      dynamicFilters.on('keyup click change', function(e) {
        $gridEl.trigger('restripe.recruit.table-tyrant');
      
        var input = $(e.target);
        var searchTerm = input.val().toLowerCase();
      
        new DynamicSearch(tableRows.toArray(), {actions: [
          DynamicSearch.updateMisses('hidden'),
          DynamicSearch.updateMisses('filtered-out'),
          DynamicSearch.updateMatches('filtered-in'),
          DynamicSearch.markMatches(),
        ]}).search(searchTerm);

        // hide empty sections, if enabled
        if ($gridEl.attr('data-search-hides-empty-sections') === "true") {
          $gridEl.find('tbody.matches').show(); //or we can't tell what hidden sections now contain matches
        
          // hide sections that have no matches
          $gridEl.find('tbody.matches').each(function(ix, el) {
            var hasMatches = $(el).find('tr.match.filtered-in').length > 0;
            var hasSearch = searchTerm.length > 0;
            var shouldShow = (hasSearch && hasMatches) || !hasSearch;
          
            $(el).prev('thead').toggle(shouldShow);
            $(el).toggle(shouldShow);
            $(el).next('tfoot.summary').toggle(shouldShow);
          });
        
          $gridEl.find('tfoot.no-matching-sections').toggle($gridEl.find('tbody.matches .filtered-in').length === 0);
        }
       
        // update the counts or show warnings
        $gridEl.find('tbody.matches').each(function(ix, el) {
          var matches = $(el);
          var summary = matches.next('tfoot.summary')
          var showing = matches.find('tr.match.filtered-in').length;
          var hasSearch = searchTerm.length > 0;
          var noResults = matches.find('.filter-no-results');

          noResults.toggleClass('hidden', !hasSearch || showing > 0);
          summary.find('.showing').toggleClass('hidden', !hasSearch);

          if (hasSearch) {
            summary.find('.showing .n').text(showing);
            summary.find('.showing .item-name').text(showing === 1 ? 'match' : 'matches');
          }
        });
      }).keyup();
    });
  }
  
  #setupSelectAll() {
    this.#tyrantEls.on('click', 'thead tr.section-headers th.selected input[name=select-all]', function(e) {
      $(this).closest('.table-tyrant').find('tr td.selected input').prop('checked', e.target.checked);
    });
    this.#tyrantEls.on('click', 'tr td.selected input', function(e) {
      if (e.target.checked) return;
    
      $(this).closest('.table-tyrant').find('thead tr.section-headers th.selected input[name=select-all]').prop('checked', false);
    });
  }
  
  #setupPagerEvents() {
    this.#tyrantEls.on('click', '.more-pages-to-see *[data-page]', function(e) {
      e.preventDefault();
    
      var page = $(e.target).attr('data-page');
      var $gridEl = $(this).closest('.table-tyrant');
      var grid = TableTyrant.Grid.closest($gridEl);
    
      grid.event('page', '-' + page);
    
      $gridEl.find('input[type=hidden][name=page]').val(page);
      grid.updateWorkspace();
    });
  }
  
  #setupRefreshAnimation() {
    this.#tyrantEls.on('ajax:send', 'form.edit-workspace', function(event) {
      $(event.target).closest('.table-tyrant').addClass('refreshing');
      $(this).find('.modal').modal('hide');
    });

    this.#tyrantEls.on('ajax:complete', 'form.edit-workspace', function(event) {
      var $form = $(event.target);
      var $gridEl = $form.closest('.table-tyrant');
      $gridEl.removeClass('refreshing saving');
      $gridEl.trigger('restripe.recruit.table-tyrant');
    });
  }

  #setupRowRefresh() {
    $(document).on("click", "[data-table-tyrant-grid][data-table-tyrant-refresh-key]", event => {
      let trigger = event.currentTarget;
      let grid = TableTyrant.Grid.withID(trigger.dataset.tableTyrantGrid);
      let params = JSON.parse(trigger.dataset.tableTyrantRefreshParams || "{}");
      grid.updateRows([trigger.dataset.tableTyrantRefreshKey], {animate: true, params})
    });
  }

  #setupUrlUpdating() {
    this.#tyrantEls.on('ajax:success', 'form.edit-workspace', function(event) {
      TableTyrant.Grid.closest($(event.target)).updateUrlFromForm();
    });
  }

  #setupErrorHandling() {
    this.#tyrantEls.on('ajax:error', 'form.edit-workspace', function(event) {
      Once.reset($(event.target).find('.once-hidden'));
    });
  }
  
  static setup() {
    TableTyrant.Columns = StagingGrid;
    TableTyrant.Filters = TableTyrantFilters;
    TableTyrant.Filters.Group = TableTyrantFilterGroups;
    TableTyrant.Grid = TableTyrantGrid;
    TableTyrant.Sorts = TableTyrantSorting;
    TableTyrant.Workspaces = TableTyrantWorkspaces;
    
    $(window).on("popstate", function(e) {
      var state = e && e.originalEvent && e.originalEvent.state;
      if (!state || !state.tableTyrantId) return;
  
      TableTyrant.Grid.updateGridFromState(state);
    });

    $(function() {
      new BusyBody({
        selector: '.table-tyrant',
        added: (el) => { new TableTyrant(el).setup() },
      });
      
      $('.table-tyrant').each(function() {
        new TableTyrant.Grid($(this)).rememberInitialState();
      });
    });
  }
}

window.TableTyrant = TableTyrant;
