import { Telescope } from "../../behavior/telescope";

let idCounter = 1;

export default class RelativeDateFormGroup {
  constructor(el) {
    this.el = el;
    this.el.id = this.el.id || `relative-date-group-${idCounter++}`;
  }

  get input() { return this.el.querySelector('input') }
  get telescope() { return new Telescope(`#${this.el.id}`) }
  
  setup() {
    this.el.dataset.telescopeMap = this.el.dataset.telescopeMap || JSON.stringify({unset: "unset", pending: "pending", past: "past", today: "today", future: "future"})
    this.telescope.val("unset");

    this.input.addEventListener("change", event => {
      this.telescope.val("pending");

      fetch(`/api/public/dates?transformer=${this.el.dataset.relativeDateTransformer}&q=${event.target.value}`)
        .then(response => response.json())
        .then(json => {
          this.telescope.val(json.today ? "today" : (json.past ? "past" : "future"));
        })
    });
  }
  
  static setup() {
    new BusyBody({
      selector: ".form-group.relative-date",
      added: (el) => new RelativeDateFormGroup(el).setup()
    });
  }
}
