import {FindAndUpdate} from 'services/find_and_update';

export var sharedContentSwap = {
  setup() {
    $(document).on('click', '[data-content-swap]', event => {
      var updates = $(event.currentTarget).data('content-swap');
      
      updates.html && FindAndUpdate.htmlImmediate(updates.html);
      updates.text && FindAndUpdate.text(updates.text);
      updates.glow && FindAndUpdate.glow(updates.glow);
      updates.classes && FindAndUpdate.classes(updates.classes);
    });
  }
};
