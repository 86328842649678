export var formRedirectOnSuccess = {
  setup() {
    $(function() {
      $(document)
        .on('ajax:success', 'form[data-redirect-on-success], a[data-redirect-on-success]', function(event) {
          let [json, status, xhr] = event.detail;
          let loc = xhr.getResponseHeader("Location");
          document.location = loc;
        })
        .on('ajax:complete', 'form[data-redirect-on-success]', function(event) {
          Once.reset($(this).find('input.once-hidden, button.once-hidden'));
        });
    });
  }
};
