export var applyHighlighter = {
  setup() {
    $(document).on('click', '.school-jumper a', function() {
      var selector = $(this).attr('href');
      $(selector).parent().next('.matches').effect("highlight", {}, 2000);
    });

    $(document).on('click', '.login-or-create .apply', function() {
      $('.matches').effect("highlight", {}, 2000);
    });
  }
};


