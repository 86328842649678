export class TableTyrantColumns {
  constructor(grid) {
    this._grid = grid;
    this._updateLabels();
    this._makeDraggable();
  }
  
  reset() {
    this.#allPanels.find('.column[data-column-default-index]').appendTo(this.#visiblePanel.find('ol'));
    this.#allPanels.find('.column:not([data-column-default-index])').appendTo(this.#hiddenPanel.find('ol'));
    this._sortListByDefaultOrder(this.#visiblePanel);
    this._sortList(this.#hiddenPanel);
    this.updateOrder();
  }
  
  search(q) {
    let visible = new DynamicSearch(this.#visiblePanel.find('*[data-search]').toArray(), {missClass: 'text-muted'});
    visible.search(q.toLowerCase());
    
    let hidden = new DynamicSearch(this.#hiddenPanel.find('*[data-search]').toArray());
    let {matched, missed} = hidden.search(q.toLowerCase());
    
    // hide groups where the only columns that matched are already in the visible panel
    $.each(this.#hiddenPanel.find('.column-group').get().reverse(), function() {
      $(this).toggleClass('hidden', !$(this).nextAll('.column:not(.hidden), .column-group:not(.hidden)').first().is('.column'));
    });
    this.#hiddenPanel.find('.zero-state').toggleClass('shown', matched.length === 0);
  }
  
  isHiddenList(el) {
    return el && $(el).is('.column-selector--hidden ol');
  }
  
  showDragOptions(from) {
    var dropLists = this.isHiddenList(from) ? this.#visiblePanel : this.#allPanels;
    dropLists
      .css('box-shadow', '0 0 5px hsl(200, 80%, 86%)');
  }
  
  hideDragOptions() {
    this.#allPanels
      .css('box-shadow', 'none');
  }
  
  updateOrder() {
    var columns = this.#visiblePanel.find('.column').map(function() {return $(this).data('column-name')});
    this._grid._el.find('input.visible-columns[type=hidden]').val(columns.toArray().join(","));
    this._updateLabels();
  }
  
  _setOrder(columns) {
    var $list = this.#visiblePanel.find('ol');
    var $items = $list.children('li.column').get();
    $items.sort(function(a, b) {
       var ixA = columns.indexOf($(a).attr('data-column-name'));
       var ixB = columns.indexOf($(b).attr('data-column-name'));
       return (ixA < ixB) ? -1 : (ixA > ixB) ? 1 : 0;
    });
    $.each($items, function(ix, item) { $list.append(item); });
    this.updateOrder();
  }
  
  sortHiddenList() {
    this._sortList(this.#hiddenPanel);
  }
  
  makeVisible(el) {
    el = ("string" !== typeof el) ? el : this._findColumnWithHeader(el);
    if (!this.isHiddenList($(el).closest('ol'))) { return false }
    
    this.#visiblePanel.find('ol').append(el);
    el.focus();
    this.updateOrder();
  }
  
  makeHidden(el) {
    el = ("string" !== typeof el) ? el : this._findColumnWithHeader(el);
    if (this.isHiddenList($(el).closest('ol'))) { return false }
    
    this.#hiddenPanel.find('ol').append(el);
    this.updateOrder();
    this.sortHiddenList();
    el.focus();
  }
  
  toggleVisible(el) {
    el = ("string" !== typeof el) ? el : this._findColumnWithHeader(el);
    if (this.isHiddenList($(el).closest('ol'))) {
      this.makeVisible(el);
    } else {
      this.makeHidden(el);
    }
  }
  
  moveUp(el) {
    this._move(el, 'prev', 'before');
  }
  
  moveDown(el) {
    this._move(el, 'next', 'after');
  }
  
  _findColumnWithHeader(header) {
    return this._grid.form.find('.column[data-column-header="' + header + '"]');
  }
  
  get #allPanels() {
    return this._grid._el.find('.column-selector');
  }
  
  get #visiblePanel() {
    return this._grid._el.find('.column-selector--visible');
  }
  
  get #hiddenPanel() {
    return this._grid._el.find('.column-selector--hidden');
  }
  
  _sortList(list) {
    this._sortListBy(list, function(a, b) {
      // always sort zero-state to end
      if ($(a).is('.zero-state')) return 1;
      if ($(b).is('.zero-state')) return -1;
      
      // sort by group first
      var ixGroupA = parseInt($(a).data('column-group-index'), 10);
      var ixGroupB = parseInt($(b).data('column-group-index'), 10);
      if (ixGroupA !== ixGroupB) return ixGroupA > ixGroupB ? 1 : -1;
      
      // and index second
      var ixA = parseInt($(a).data('column-index'), 10);
      var ixB = parseInt($(b).data('column-index'), 10);
      ixA = isNaN(ixA) ? -1 : ixA;
      ixB = isNaN(ixB) ? -1 : ixB;
      return ixA > ixB ? 1 : -1;
    });
  }
  
  _sortListByDefaultOrder(list) {
    this._sortListBy(list, function(a, b) {
      var ixA = parseInt($(a).data('column-default-index'), 10);
      var ixB = parseInt($(b).data('column-default-index'), 10);
      return ixA > ixB ? 1 : -1;
    });
  }
  
  _sortListBy(list, by) {
    list.find('ol li').sort(by).prependTo(list.find('ol'));
  }
  
  _move(el, direction, location) {
    el = ("string" !== typeof el) ? el : this._findColumnWithHeader(el);
    if (this.isHiddenList($(el).closest('ol'))) { return false }
    
    var $el = $(el);
    var $direction = $el[direction]();
    if ($direction.length === 0) return;
    $direction[location]($el);
    el.focus();
    this.updateOrder();
  }
  
  _updateLabels() {
    this.#visiblePanel.find('.column').each(function(ix) {
      $(this).attr('aria-label-original') || $(this).attr('aria-label-original', $(this).attr('aria-label'));
      $(this).attr('aria-label', $(this).attr('aria-label-original') + ". Shown as column " + (ix + 1));
    });
    
    this.#hiddenPanel.find('.column').each(function() {
      $(this).attr('aria-label-original') || $(this).attr('aria-label-original', $(this).attr('aria-label'));
      $(this).attr('aria-label', $(this).attr('aria-label-original') + ". Hidden.");
    });
  }
  
  _makeDraggable() {
    var self = this;
    var grid = self._grid;
    if (grid._el.is('.no-workspaces')) return;
    
    var $resultsTable = grid._el.find('table.results');
    if ($resultsTable.data('is-draggable') === true) return;
    
    var doIt = function() {
      $resultsTable.dragtable({
        dragFooter: false,
        items: '.dragtable-drag-handle'
      });
    };
    
    $resultsTable.data('is-draggable', true);
    $resultsTable.on('dragtablestop', function(event, data) {
      self._setOrder(data.order);
      grid.updateWorkspaceWith({
        animate: 'saving',
        overrides: {gimme: 'popover'},
        then: json => { grid.workspaces.isDirty = json.dirty },
      });
    });
    
    doIt();
    this._grid._el.on('ajax:success', 'form.edit-workspace', doIt);
  }
}
