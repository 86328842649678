import Reorder from 'behavior/reorderable';
import Glow from 'behavior/glow';
import {FindAndUpdate} from 'services/find_and_update';

class VeilReorder extends Reorder {
  constructor(selector) {
    super(selector);
  }

  get root() {
    return this.el()[0];
  }
  
  get url() {
    return this.el().data('reorder-url');
  }

  get itemSelector() {
    return 'tr.veil-row:not(.not-sortable)';
  }

  get items() {
    return Array.from(this.root.querySelectorAll(this.itemSelector));
  }
  
  get data() {
    return {order: this.items.map(e => e.dataset.veilId)};
  }
  
  sortableOptions() {
    return {
      ...super.sortableOptions(),
      axis: "y",
      items: this.itemSelector,
    };
  }
  
  spinner() {
    return this.el().find('.reorder-spinner');
  }
  
  sortStarted(event, ui) {
    ui.placeholder
      .css({height: ui.item.height()})
      .append(
        $('<td>')
          .attr('colspan', ui.item.find('th, td').length)
          .addClass('text-muted')
          .append(Components.iconic('hand-point-left'))
          .append(" Move here")
      );
  }
  
  updateStarted() {
    this.spinner().removeClass('hidden');
  }
  
  updateDone() {
    this.spinner().addClass('hidden');
  }
  
  updateSuccess(json, _status, _xhr) {
    new Glow(this.el().closest('table').find('.header th:last'), 'Order saved', 'success').animate();
    FindAndUpdate.html(json.updates)
  }
}

export var recruitmentVeilReordering = new VeilReorder('.js-veil-reorderable');
