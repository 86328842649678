class GreedyCheckboxEvent {
  constructor(event) {
    this._target = $(event.target);
    this.scope = new GreedyCheckboxScope(this._target.closest('*[data-greedy-checkbox-value]'));
  }
  
  get greedy() { return this.scope.greedyValue === this._target.val(); }
  
  static setup() {
    $(document).on('click', '*[data-greedy-checkbox-value] input[type=checkbox]', (event) => {
      let gEvent = new GreedyCheckboxEvent(event);
      gEvent.scope.greedy = gEvent.greedy;
    });
  }
}

class GreedyCheckboxScope {
  constructor(el) {
    this._el = $(el);
  }
  
  get greedyValue() { return (this._el.data('greedy-checkbox-value') || '').toString(); }
  get $checkboxes() { return this._el.find('input[type=checkbox]'); }

  set greedy(val) {
    this.$checkboxes.
      filter(':checked').
      filter((ix, e) => val ? e.value !== this.greedyValue : e.value === this.greedyValue).
      prop('checked', false).
      trigger('change');
  }
}

export var formGreedyCheckbox = GreedyCheckboxEvent;
