export var analystApplicantReview = {
  setup() {
    document.addEventListener('input', (event) => {
      if (event.target.matches('.modal .reference_email input')) this.triggerEmailWarning(event);
    });
  },

  triggerEmailWarning(event) {
    let persisted_email_field = event.target.closest('.modal').querySelector(".reference-persisted-email");
    let new_email = event.target.value;
    
    if (persisted_email_field && persisted_email_field.value !== new_email) {
      $(".modal.in .updating-email").slideDown();
      $(".modal.in .checkbox").find(':input').attr("disabled", false);
    } else {
      $(".modal.in .updating-email").slideUp();
      $(".modal.in .checkbox").find(':input').attr("disabled", true);
    }
  }
}
