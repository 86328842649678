import Appointments from "../../components/appointments";

export let waiversCandidateInformation = {
  showAppointments() {
    let appointments = new Appointments(document.querySelector('#candidate-information .js-appointments'));
    appointments.setEditable(true).ensureAtLeastOneAppointment();
  },
  
  setup() {
    $(document).on('did-change.telescope', '#candidate-information', (event, telescope, scopes) => {
      if (scopes.added.includes('allows-employment-details')) {
        waiversCandidateInformation.showAppointments();
      }
    });
    
    $(document).on('click', '#candidate-information .make-editable', (event) => {
      waiversCandidateInformation.showAppointments();
    });
  }
};
