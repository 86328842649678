export var adminPermissionOptions = {
  setup() {
    $(() => {
      this.enableButtonsWhenDirty();
    });
  },
  
  enableButtonsWhenDirty() {
    $(document).on('click', 'fieldset.permission-option input[type=radio]', function() {
      var radio = $(this);
      var fieldset = radio.closest('fieldset');
      var updateButton = fieldset.find('button.update-now');
      var needsUpdate = radio.attr('data-started-checked') !== radio.is(':checked').toString();
      
      updateButton.prop('disabled', !needsUpdate).toggleClass('btn-primary', needsUpdate);
      fieldset.find('.cancel').toggleClass('shown', needsUpdate);
    });
  }
};
