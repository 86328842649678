import {Telescope} from 'behavior/telescope';

export const sharedDoomed = {
  _getOrSetAttribute($el, attr, fn) {
    if ($el.attr(attr) === undefined) {
      $el.attr(attr, fn.call ? fn() : fn);
    }
  },

  setupTrigger($trigger) {
    if ($trigger.is('[data-doomed-is-setup]')) return;
    $trigger.attr('data-doomed-is-setup', true);

    var $doomable = $trigger.closest('.js-doomable');
    this._getOrSetAttribute($trigger, 'data-telescope-target', function () {
      return '#' + $doomable.uniqueId().attr('id');
    });
    this._getOrSetAttribute($trigger, 'data-telescope-value', 'doomed');
    this._getOrSetAttribute($trigger, 'data-telescope-value-unchecked', 'spared');
    this.setupDoomable($doomable);

    Telescope.useNewValue($trigger);
  },

  setupDoomable($doomable) {
    this._getOrSetAttribute($doomable, 'data-telescope-map', JSON.stringify({
      doomed: 'doomed',
      spared: 'spared'
    }));
  },

  setup() {
    $(document).on('click', '.js-doomable *[data-toggle="doomed"]', function(event) {
      Telescope.useNewValue(this);
    });

    new BusyBody({
      selector: '.js-doomable',
      added: (el) => {
        el.querySelectorAll('*[data-toggle="doomed"]').forEach((trigger) => {
          this.setupTrigger($(trigger));
        });
      }
    });
  }
};
