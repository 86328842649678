class FillFormFields {
  constructor(selector='form', namePattern='$1') {
    this._selector = selector;
    this._namePattern = namePattern;
  }
  
  get $form() {
    return $(this._selector);
  }
  
  fill(valuesByName) {
    for (var name in valuesByName) {
      this.fieldNamed(name).val(valuesByName[name]);
    }
  }
  
  fillFromElement(element) {
    this.fill($(element).data('fill-fields'));
  }
  
  fieldNamed(name) {
    let nameAttr = this.fieldNameFor(name);
    return this.$form
      .find(`input[name="${nameAttr}"], textarea[name="${nameAttr}"], button[name="${nameAttr}"]`);
  }
  
  fieldNameFor(name) {
    return this._namePattern.replace('$1', name);
  }
}

export var formFillFields = {
  setup() {
    $(document).on('click', 'a[data-fill-fields]', e => {
      let $this = $(e.currentTarget);
      new FillFormFields(
        $this.data('fill-fields-target'),
        $this.data('fill-fields-name-pattern'),
      ).fillFromElement($this);
    });
  }
};
