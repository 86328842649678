export var recruitmentCommitteeManagement = {
  findValue(text, select) {
    for (let option of select.options) {
      if (option.innerText === text) {
        return option.value;
      }
    }
  },
  
  setup() {
    document.addEventListener('click', (event) => {
      if (event.target.matches('.committee-members *[data-role]')) {
        let role = event.target.dataset.role;
        let section = event.target.closest('section');
        let rolePicker = section.querySelector('.role-picker');
        
        rolePicker.value = recruitmentCommitteeManagement.findValue(role, rolePicker);
        rolePicker.dispatchEvent(new Event('change'));
        
        $(section.querySelector('.user-picker')).select2('open');
      }
    });
  }
};
