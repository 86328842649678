export var recruitmentChecklistModal = {
  closeModalToSeeGlow() {
    $(document).on('click', '#really-submit-as-plan a[data-toggle=glow]', e => {
      $(e.target).closest('.modal').modal('hide');
    });
  },
  
  setup() {
    this.closeModalToSeeGlow();
  }
};
