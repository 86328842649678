export var recruitmentCrosslist = {
  add() {
    let donor = document.querySelector('#crosslist-template');
    // prepare donor for cloning
    donor.querySelectorAll('select').forEach(selector => {
      selector.removeAttribute('id');
    });
    donor.querySelectorAll('select.select2-hidden-accessible').forEach(selector => {
      $(selector).select2('destroy');
    });

    let clone = donor.cloneNode(true);
    clone.removeAttribute('id');
    clone.disabled = false;
    clone.querySelectorAll('select').forEach(el => el.disabled = false);

    let $clone = $(clone);
    let $tip = $('#crosslist-tip');
    $clone.insertBefore($tip);
    $clone.show();
    $tip.show();
    $tip.get(0).classList.remove("hidden");

    if ($clone.is(':visible')) {
      setTimeout(() => {
        new Glow($clone, '', 'success').animate();
      }, 200);
    }
    return clone;
  },

  fill(row, attrs) {
    row.querySelector('.crosslist-id').value = attrs.id;
    let departmentPicker = row.querySelector('.department-picker');
    let schoolPicker = row.querySelector('.school-picker');
    
    departmentPicker.value = attrs.department_id;
    departmentPicker.dispatchEvent(new Event("change"));
    schoolPicker.value = attrs.school_id;
    schoolPicker.dispatchEvent(new Event("change"));

    if (attrs.disabled === true) {
      row.querySelector('.js-remove').remove();
      row = $(row);
      $(departmentPicker).add(row).prop('disabled', true);
      $(schoolPicker).add(row).prop('disabled', true);
    }
  },

  remove(target) {
    let row = target.closest('fieldset');
    row.querySelector(".is-doomed").value = 'true';
    row.querySelectorAll("select.select").forEach(selectElem => {
      selectElem.disabled = true;
    });

    new Glow($(row), '', 'danger').animate().then(() => {
      row.style.display = "none";
      if ($('.crosslist:visible').length === 0) {
        $('#crosslist-tip').hide();
      }
    });
  },

  loadExistingCrosslists() {
    let loadingIcon = document.querySelector("#crosslist-loading-icon");
    let tip = document.querySelector("#crosslist-tip");
    let div = document.querySelector("#existing-crosslists");
    loadingIcon.classList.remove("hidden");
    tip.classList.add("hidden");
    div.innerHTML = "";
    
    new Telescope($('#recruitment-crosslist')).val('Loading Crosslists');
    fetch(String(window.location).replace('details', 'general_information.json'))
      .then(response => {
        return response.json()
      })
      .then(data => {
        let existingCrosslists = data['general_information'];
        loadingIcon.classList.add("hidden");

        existingCrosslists.forEach(crosslist => {
          let row = this.add();
          this.fill(row, crosslist);
          div.appendChild(row);
        });

        new Telescope($('#recruitment-crosslist')).val('Loaded Crosslists');
      })
  },

  setup() {
    document.addEventListener('click', event => {
      if (event.target.closest('.js-add-crosslist')) {
        this.add();
      } else if (event.target.closest('.crosslist .js-remove')) {
        this.remove(event.target);
      } else if (event.target.closest('button[data-target="#general-information"]')) {
        this.loadExistingCrosslists();
      }
    });
    document.addEventListener('ajax:error', (event) => {
      this.loadExistingCrosslists();
    });
  }
};
