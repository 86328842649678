class BulkRedactionHoverboard {
  constructor(el) {
    this.el = el.closest('#bulk-redaction-hoverboard');
  }

  get activeApplicant() {
    return this.el.querySelector('.bulk-redaction-applicant.active');
  }

  get activeTab() {
    return this.el.querySelector('.tab-pane.active');
  }

  get applicantEls() { 
    return this.el.querySelectorAll('.bulk-redaction-applicant');
  }

  setup() {
    this.#setupApplicantEls();
    this.#setupRedactButtons();
    this.#setupCloseConfirmation();
  }

  #setupApplicantEls() {
    this.applicantEls.forEach((applicantEl) => {
      applicantEl.addEventListener('click', (event) => {
        const redactomaticToSet = this.el.querySelector(`.tab-pane[data-application-id="${event.currentTarget.dataset.applicationId}"]`);
        if (redactomaticToSet) {
          this.activeApplicant.classList.remove("active");
          this.activeTab.classList.remove("active");
          event.currentTarget.classList.add("active");
          redactomaticToSet.classList.add("active");
        }
      });
    });
  }

  #setupRedactButtons() {
    this.el.querySelectorAll("button.redact-and-view").forEach((redactAndViewButton) => {
      redactAndViewButton.addEventListener('click', (event) => {
        const bulkRedactomatic = redactAndViewButton.closest('.bulk-redactomatic');
        bulkRedactomatic.querySelectorAll(".redact-button").forEach((redactButton) => {
          redactButton.disabled = true;
        });
      });
    });

    this.el.querySelectorAll("button.redact-and-continue").forEach((redactAndContinueButton) => {
      const bulkRedactomatic = redactAndContinueButton.closest('.bulk-redactomatic');
      const currentApplicationId = bulkRedactomatic.dataset.applicationId;
      const nextApplicantId = this.#applicantIdAfter(currentApplicationId);

      if (nextApplicantId) {
        redactAndContinueButton.addEventListener('click', (event) => {
          bulkRedactomatic.querySelectorAll(".redact-button").forEach((redactButton) => {
            redactButton.disabled = true;
          });
  
          this.#jumpToApplicant(nextApplicantId);
        });
      } else {
        redactAndContinueButton.classList.add('disabled');
        redactAndContinueButton.disabled = true;
      }
    });

    this.el.querySelectorAll("button.previous-application").forEach((previousApplicationButton) => {
      const currentApplicationId = previousApplicationButton.closest('.bulk-redactomatic').dataset.applicationId;
      const previousApplicantId = this.#applicantIdBefore(currentApplicationId);

      if (previousApplicantId) {
        previousApplicationButton.addEventListener('click', (event) => {
          this.#jumpToApplicant(previousApplicantId);
        });
      } else {
        previousApplicationButton.classList.add('disabled');
        previousApplicationButton.disabled = true;
      }
    });

    this.el.querySelectorAll("button.next-application").forEach((nextApplicationButton) => {
      const currentApplicationId = nextApplicationButton.closest('.bulk-redactomatic').dataset.applicationId;
      const nextApplicantId = this.#applicantIdAfter(currentApplicationId);

      if (nextApplicantId) {
        nextApplicationButton.addEventListener('click', (event) => {
          this.#jumpToApplicant(nextApplicantId);
        });
      } else {
        nextApplicationButton.classList.add('disabled');
        nextApplicationButton.disabled = true;
      }
    });
  }

  #setupCloseConfirmation() {
    const redactomaticEls = Array.from(this.el.querySelectorAll('.redactomatic'));
    const observer = new MutationObserver((mutations) => {
      if (mutations[0].attributeName === 'class') {
        const containsUnsaved = redactomaticEls.some(redactomaticEl => redactomaticEl.classList.contains('unsaved-redaction') || redactomaticEl.classList.contains('unsaved-annotation'));
        this.el.classList.toggle('close-confirmation', containsUnsaved);

        const updatedRedactomaticEl = mutations[0].target;
        const applicationId = updatedRedactomaticEl.closest('.bulk-redactomatic').dataset.applicationId;
        const unsavedAnnotationsWarningEl = this.el.querySelector(`.bulk-redaction-applicant[data-application-id="${applicationId}"] .unsaved-annotation-warning`);
        const unsavedRedactionsWarningEl = this.el.querySelector(`.bulk-redaction-applicant[data-application-id="${applicationId}"] .unsaved-redaction-warning`);
        unsavedAnnotationsWarningEl.classList.toggle('hidden', !updatedRedactomaticEl.classList.contains('unsaved-annotation'));
        unsavedRedactionsWarningEl.classList.toggle('hidden', !updatedRedactomaticEl.classList.contains('unsaved-redaction'))
      }
    })
    redactomaticEls.forEach(redactomaticEl => observer.observe(redactomaticEl, { attributes: true }));
  }

  #jumpToApplicant(id) {
    if (!id) return;
    this.el.querySelector(`.bulk-redaction-applicant[data-application-id="${id}"]`).click();
  }

  #applicantIdBefore(id) {
    const currentApplicant = this.el.querySelector(`.bulk-redaction-applicant[data-application-id="${id}"]`);
    let previousApplicant = currentApplicant.previousElementSibling;

    while (previousApplicant) {
      if (previousApplicant.matches('.bulk-redaction-applicant.redacted-file-present, .bulk-redaction-applicant.redacted-file-absent')) {
        return previousApplicant.dataset.applicationId;
      }
      previousApplicant = previousApplicant.previousElementSibling;
    }
  }

  #applicantIdAfter(id) {
    const currentApplicant = this.el.querySelector(`.bulk-redaction-applicant[data-application-id="${id}"]`);
    let nextApplicant = currentApplicant.nextElementSibling;

    while (nextApplicant) {
      if (nextApplicant.matches('.bulk-redaction-applicant.redacted-file-present, .bulk-redaction-applicant.redacted-file-absent')) {
        return nextApplicant.dataset.applicationId;
      }
      nextApplicant = nextApplicant.nextElementSibling;
    }
  }
}

export var applicationBulkRedactionHoverboard = {
  setup: function() {
    new BusyBody({
      selector: '.hoverboard--body---bulk-redaction-content',
      added: el => {
        const bulkRedactionHoverboard = new BulkRedactionHoverboard(el);
        bulkRedactionHoverboard.setup();
      }
    });
  }
};
