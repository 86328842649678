export var applicationBulkApplicationFileDownloadModalPreflight = {
  setup: function() {
    $(document).on('show.bs.modal', '.bulk-application-file-download-modal', event => {
      let modal = event.target;
      let form = modal.closest('form');
      let application_ids = $(form).selectedCollector().values;

      let checkboxes = modal.querySelectorAll('input[type=checkbox][class="check_boxes optional"]');
      let submitbtn = modal.querySelector('.modal-editor--submit-button');
      let link = modal.querySelector('a.bulk-application-file-download-modal--preflight-trigger');

      function anyChecked() {
        return modal.querySelectorAll('input[type=checkbox][class="check_boxes optional"]:checked').length > 0;
      }

      function toggleSubmitButton() {
        submitbtn.disabled = !anyChecked();
      }

      link.dataset.params = new URLSearchParams({selected: application_ids.join(',')}).toString();
      
      link.click();

      let greedyValue = $(modal.querySelector('div.application_file_type_id')).attr('data-greedy-checkbox-value');
      let greedyLabel = $(modal.querySelector('label.bulk-application-file-download-modal--bundle-label')).removeClass('starts-hidden');
      $('input[value="' + greedyValue + '"]').closest('.checkbox').before(greedyLabel);

      checkboxes.forEach(checkbox => {
        checkbox.addEventListener('click', () => {
          toggleSubmitButton();
        })
      })
      toggleSubmitButton();
    }).on('hidden.bs.modal', '.bulk-application-file-download-modal', event => {
      let modal = event.target;
      
      let link = modal.querySelector('a.bulk-application-file-download-modal--preflight-trigger');
      link.classList.remove('hidden');
      
      modal.querySelector('.bulk-application-file-download-modal--preflight').classList.add('hidden');
      
      modal.querySelector('.bulk-application-file-download-modal--disabled-save').classList.remove('hidden');
      modal.querySelector('.modal-editor--submit-button').classList.add('hidden');
    });
  }
};
