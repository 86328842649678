class ApplicationProgressDotsPopover {
  constructor(el) {
    this._el = el;
    this._tipDiv = $('<div class="progress-dots progress-dots---tip">');
  }
  
  build() {
    this._el.querySelectorAll('.dot[title]').forEach((dot) => {
      var title = dot.getAttribute('title');
      
      var line = document.createElement('article');
      line.classList.add('progress-dots--line');
      line.classList.add(dot.parentElement.className);
      line.append(dot.cloneNode());
      line.append(' ');
      
      var label = document.createElement('span');
      label.classList.add('progress-dots--line-label');
      label.append(title);
      line.append(label);
      
      this._tipDiv.append(line);
    });
  }
  
  attach() {
    this._el.classList.add('progress-dots---attached');
    this.build();
    
    $(this._el).popover({
      content: this._tipDiv,
      title: 'Recruitment Requirements',
      html: true,
      trigger: 'hover',
      placement: "bottom"
    })
  }
}

export var applicationProgressDots = {
  setup: function() {
    new BusyBody({
      selector: 'td.applicant .progress-dots:not(.progress-dots---attached):not(.progress-dots---tip)',
      added: (dots) => {new ApplicationProgressDotsPopover(dots).attach()},
    });
  }
}
