import Rails from "@rails/ujs";

export var applicationActivityLog = {
  findLanding() {
    return $('dd.application-review--pillbox---last-modified .application-review--log-landing');
  },
  
  toggleLanding() {
    var landing = this.findLanding();

    if (landing.is(".hidden")) {
      Rails.fire(landing.find(".autoupdate-trigger")[0], 'click');
    }
    
    landing.toggleClass('hidden');
  },
  
  hideLanding() {
    this.findLanding().addClass('hidden');
  },
  
  setup() {
    $(document).on('click', '.application-review--log-link', e => {
      e.preventDefault();
      this.toggleLanding();
    });
    
    $(document).on('click', '.application-review', e => {
      if (!e.target.closest('.application-review--pillbox---last-modified, .hoverboard--autoupdate-trigger')) {
        this.hideLanding();
      }
    });
  }
};

