export var applicationLevelSelector = {
  makeActive($level) {
    var $selector = $level.closest('.level-selector');
    
    // reset the other levels
    var $otherLevels = $selector.find('.level-selector--level').not($level);
    $otherLevels.removeClass('active');
    $otherLevels.find('button').removeClass('btn-primary disabled');
    
    // and set styles on the new selection
    $level.addClass('active');
    $level.find('button').addClass('btn-primary disabled');
    $level.find(':radio').prop('checked', true).trigger('change');
    
    // and let the whole component know
    new Telescope($("#" + $selector.attr('id'))).val($level.is('.current') ? 'level-remaining' : 'level-changing');
  },
  
  setup() {
    var levelSelector = this;
    
    $(() => {
      $(document).on('keydown', '.level-selector--level', e => {
        if (e.keyCode === '13') { e.preventDefault(); e.stopPropagation() }
      });
          
      $(document).on('click', '.level-selector--level', e => {
        if ($(e.target).closest('button') && !$(e.target).is('input')) {
          e.preventDefault(); e.stopPropagation() 
        };
        levelSelector.makeActive($(e.currentTarget).closest('.level-selector--level'));
      });
      
      $(document).on('did-change.telescope', '#select-level', (event, telescope, scopes) => {
        $(event.target).find('.swizzled-unless-remaining').toggleClass('swizzled', scopes.removed.includes('remaining'));
      });
    });
  }
};
