export var exemptionWizardEmailAddresses = {
  $template() {
    return $('#candidate-email-address-template');
  },
  
  $addItem() {
    return $('.js-add-an-email-address').closest('li');
  },
  
  addSlot(address) {
    var $slot = this.$template().clone(true)
      .attr('id', '')
      .removeClass('hidden');
    $slot.find(':input')
      .prop('disabled', false)
      .val(address);
  
    $slot.insertBefore(this.$addItem());
    this.disableRemoveOnLastSlot();
    return $slot;
  },
  
  removeSlot($li) {
    $li.remove();
    this.disableRemoveOnLastSlot();
  },
  
  disableRemoveOnLastSlot() {
    var $removeLinks = $('li:not(.hidden) .js-remove-this-email-address');
    $removeLinks
      .toggleClass('looks-disabled', $removeLinks.length === 1)
      .prop('disabled', $removeLinks.length === 1);
  },
  
  setup() {
    var emailAddresses = this;
    
    $(() => {
      emailAddresses.disableRemoveOnLastSlot();
      $(document).on('click', '.js-add-an-email-address', e => {
        e.preventDefault();
        emailAddresses.addSlot();
      }).on('click', '.js-remove-this-email-address', e => {
        e.preventDefault();
        emailAddresses.removeSlot($(e.target).closest('.email-address'));
      }).on('validate-complete.ged', e => {
        emailAddresses.disableRemoveOnLastSlot();
      })
    });
  }
};
