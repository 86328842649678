export class TableTyrantFilterGroups {
  constructor(groupEl) {
    this._groupEl = $(groupEl);
  }
  
  get grid() { return TableTyrant.Grid.closest(this._groupEl); }
  
  get name() { return this._groupEl.attr('data-filter-group-name'); }
  
  get engaged() { return this._groupEl.is('.engaged'); }
  set engaged(newValue) {
    this.grid.event(`filter-group-${newValue ? 'engaged' : 'disengaged'}`, `-${this.name}`);
    
    this._groupEl
      .toggleClass('engaged', newValue)
      .toggleClass('disengaged', !newValue)
      .find('fieldset').prop('disabled', !newValue);
    this._groupEl
      .find('select.select2-hidden-accessible').prop('disabled', !newValue);
    this.grid.form.find('.filter-group-disengaged-bookmark[data-filter-group-name="' + this.name + '"]')
      .prop('disabled', newValue);
  }
  
  toggleAll() {
    var newState = this._groupEl.is('.none-checked');
    this._groupEl.find('input:checkbox').prop('checked', newState).trigger('change');
  }
  
  updateAllState() {
    var checkboxes = this._groupEl.find('input:checkbox').length;
    var checked = this._groupEl.find('input:checkbox:checked').length;
    this._groupEl
      .toggleClass('none-checked', 0 === checked)
      .toggleClass('all-checked', checkboxes === checked);
  }
  
  static closest(from) {
    return new TableTyrantFilterGroups($(from).closest('.filter-group'));
  }
}
