class EntropyInput {
  get $input() { return this._input; }
  get $form() { return this._form || (this._form = this.$input.closest('form')); }
  get $saveButton() { return this._saveButton || (this._saveButton = this.$form.find('button.btn-primary')); }
  get $rating() { return this._rating || (this._rating = this.$form.find('.entropy-rating')); }
  get $meter() { return this._meter || (this._meter = this.$form.find('.entropy-meter')); }
  get $confirmationInput() {
    var id = this.$input.attr('id');
    return $(`#${id}_confirmation`);
  }
  
  get percentOfHigh() { return this.$meter.val() * 100.0 / this.$meter.attr('high'); }
  get percentOfMax() { return this.$meter.val() * 100.0 / this.$meter.attr('max'); }
  
  constructor(input) {
    this._input = $(input);
    
    if (!this._input.data('entropy-input-set-up')) {
      this._input.data('entropy-input-set-up', true);
      this.setup();
    }
  }
  
  updateEntropy() {
    $.ajax({
      type: "POST",
      url: this.$input.data('entropy-action'),
      data: {
        password: this.$input.val()
      },
      success: (xhr) => {
        this.updateMeter(xhr);
        this.updateRating();
        this.toggleSubmit();
      }
    });
  }
  
  updateMeter(xhr) {
    this.$meter.val(xhr.entropy);
    this.$meter.text(xhr.result);
    this.$form.find('.entropy-feedback-message').text(xhr.message);
  }
  
  updateRating() {
    this.$rating
      .toggleClass('fair', this.percentOfHigh >= 100)
      .toggleClass('good', this.percentOfMax >= 100)
      .find('.backfill').css('width', `${99 - this.percentOfMax}%`);
  }
  
  toggleSubmit() {
    let confirmation = this.$confirmationInput;
    let confirmed = confirmation.length === 0 || confirmation.val() === this.$input.val();
    let enable = confirmed && this.percentOfHigh >= 100;
    
    this.$saveButton.toggleClass('looks-disabled', !enable);
  }
  
  setup() {
    this.$input.after($("<div class='entropy-rating'>").append($("<div class='backfill' style='width: 99%;'/>")));
    this.toggleSubmit();
    this.updateEntropy();
    
    this.$confirmationInput.on('keyup change', () => this.toggleSubmit());
  }
  
  static setup() {
    $(document).on('keyup change', 'input[data-entropy-check]', function(event) {
      new EntropyInput(event.target).updateEntropy();
    });
    
    new BusyBody({
      selector: 'input[data-entropy-check]',
      added: (e) => new EntropyInput(e).setup(),
    });
  }
}

export var formEntropyChecker = EntropyInput;
