import Translations from '../../services/translations';

export var adminEmployeeRecordTableTyrant = {
  updateList(toBeDeleted, i18n) {
    $('.delete-selected').each(function() {
      toBeDeleted.innerHTML = '';
      Components.tag(toBeDeleted,
        Components.tag('tr',
          Components.tag("th", i18n.t('admin.employee_records.bulk_delete.summary_table.headers.hiring_process_code')),
          Components.tag("th", i18n.t('admin.employee_records.bulk_delete.summary_table.headers.employee_name')),
          Components.tag("th", i18n.t('admin.employee_records.bulk_delete.summary_table.headers.employee_id')),
        )
      );
      
      $('.delete-selected').selectedCollector().checkedCheckboxes.forEach((checkbox) => {
        Components.tag(toBeDeleted,
          Components.tag('tr',
            Components.tag("td", checkbox.dataset.hiringProcessCode),
            Components.tag("th", checkbox.dataset.name, {scope: 'row'}),
            Components.tag("td", checkbox.dataset.employeeId),
          )
        );
      });
    });
  },
  
  async setup() {
    let i18n = await Translations.create(['admin']);
    let toBeDeleted = Components.tag("table", {class: 'to-be-deleted clean-table'}, {style: 'width: 100%'});
    let toBeDeletedDetails = Components.tag("details", Components.tag("summary", "View all selected applicants"), toBeDeleted);
    let warningBox = Components.tag('div', {class: "alert alert-danger delete-employee-records--confirmation"},
      Components.tag('label', {class: 'delete-employee-records--confirmation-label'},
        Components.tag('input', {type: 'checkbox'}, cb => cb.addEventListener('change',
          e => cb.closest('.modal').querySelector('.btn.confirm').classList.toggle('disabled', !cb.checked)
        )),
        " ",
        i18n.t('admin.employee_records.bulk_delete.confirmation'),
      ));

    adminEmployeeRecordTableTyrant.updateList(toBeDeleted, i18n);
    $(document).on('selection-changed', event => adminEmployeeRecordTableTyrant.updateList(toBeDeleted, i18n));
    
    $(document).on('show.bs.modal', '#ujs-confirmation.delete-employee-records', function() {
      let modalBody = this.querySelector('.modal-body');
      modalBody.append(toBeDeletedDetails);
      modalBody.append(warningBox);

      let confirmationCheckbox = modalBody.querySelector('input[type="checkbox"]');
      confirmationCheckbox.checked = false;
    });
  }
};
