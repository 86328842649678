class ApprovalDocumentTemplateEditModal {
  constructor(modal) {
    this.modal = modal;
  }

  get reportType() {
    return this.modal.dataset.reportType;
  }

  get form() {
    return this.modal.closest('form');
  }

  get previewForEditModal() {
    return document.querySelector(`.template-preview-for-edit[data-report-type="${this.reportType}"]`);
  }

  setupPreviewForEdit() {
    this.previewForEditModal.querySelector('.template-preview--spinner').classList.remove('hidden');

    const formData = new FormData(this.form);
    const contentBlocks = formData.get("content_blocks");
    const selectedJPF = this.previewForEditModal.querySelector('.select2-selection__rendered').innerText.match(/JPF?\d+/i);
    const previewForEditInput = this.previewForEditModal.querySelector('input.staged-items');
    const previewIFrame = this.previewForEditModal.querySelector('iframe');
    let previewRelPath = this.previewForEditModal.dataset.previewPath;
    if (selectedJPF) previewRelPath = previewRelPath.replace(/JPF?\d+/i, selectedJPF[0]);
    const url = new URL(previewRelPath, document.location);

    url.searchParams.set('content_blocks', contentBlocks);
    previewIFrame.src = url.href;
    previewForEditInput.value = contentBlocks;
  }

  resetPreviewForEdit() {
    this.previewForEditModal.querySelector('iframe').src = '';
  }

  setupPreviewButton() {
    const previewButton = this.modal.querySelector('.template-edit-preview-button');
    const stagedItems = this.modal.querySelector('.staged-items');
    stagedItems.addEventListener('change', event => {
      previewButton.disabled = !this.modal.querySelectorAll(".item-selector--staged li.item:not(.hidden)").length;
    });
  }
}

export var adminApprovalDocumentTemplateEditModal = {
  setup: function() {
    $(document).on('shown.bs.modal', '.template-preview-for-edit', event => {
      const reportType = event.target.dataset.reportType;
      const modal = new ApprovalDocumentTemplateEditModal(document.querySelector(`.template-preview[data-report-type="${reportType}"]`));
      modal.setupPreviewForEdit();
    }).on('hidden.bs.modal', '.template-preview-for-edit', event => {
      const reportType = event.target.dataset.reportType;
      const modal = new ApprovalDocumentTemplateEditModal(document.querySelector(`.template-preview[data-report-type="${reportType}"]`));
      modal.resetPreviewForEdit();
    }).on('show.bs.modal', '.edit-approval-document-template-modal', event => {
      const modal = new ApprovalDocumentTemplateEditModal(event.target);
      modal.setupPreviewButton();
    });
  }
}
