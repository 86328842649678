import {Pluralizable} from 'components/pluralizable';

export default class BulkReferenceRequest {
  get selectedCollector() { return $(this.editorModal).closest('form').selectedCollector() }
  
  get editorModal() { return document.querySelector('.bulk-reference-request-modal') }
  get checkedReferenceInputs() { return document.querySelectorAll('.bulk-reference-request-modal--pick-record:checked') }
  get checkedReferenceIds() { return Array.from(this.checkedReferenceInputs).map(e => e.value) }
  get checkedApplicationIds() { return [...new Set(Array.from(this.checkedReferenceInputs).map(c => c.dataset.applicationId))] }
  get checkedReferenceEls() { return Array.from(this.checkedReferenceInputs).map(cb => cb.closest('.bulk-records-rundown--list-item').querySelector('.bulk-reference-request-modal--reference-for-application')) }
  get recipientListItems() { return Array.from(this.editorModal.querySelectorAll('.bulk-records-rundown--list-item')) }
  
  get recipientsSummary() { return this.editorModal.querySelector('.bulk-reference-request-modal--to-line') }
  get referenceSummary() { return new Pluralizable(document.querySelector('.bulk-reference-request-modal--reference-count')) }
  get applicationSummary() { return new Pluralizable(document.querySelector('.bulk-reference-request-modal--application-count')) }
  
  get previewModal() { return document.querySelector('.bulk-reference-request-preview') }
  set previewReferenceIds(v) { this.previewModal.querySelector('input[name=reference_ids]').value = v }
  get previewRecipientsHolder() { return this.previewModal.querySelector('.bulk-reference-request-preview--recipients') }
  
  preflight(applicationIds = this.selectedCollector.values) {
    let filterValues = {};
    this.editorModal.querySelectorAll('.bulk-records-rundown--action input').forEach(cb => {
      filterValues[cb.name] = cb.checked ? "on" : "off";
    });
    
    let link = this.editorModal.querySelector('a.bulk-reference-request-modal--preflight-trigger');
    link.dataset.params = new URLSearchParams({
      ...filterValues,
      selected: applicationIds.join(','),
      checked: this.checkedReferenceInputs.length > 0 ? this.checkedReferenceIds : null,
    }).toString();
    link.click();
  }
  
  resetEditorModal() {
    this.#resetPreflight();
    this.#resetFooter();
  }
  
  #resetPreflight() {
    let modal = this.editorModal;
    modal.querySelector('a.bulk-reference-request-modal--preflight-trigger').classList.remove('hidden');
    modal.querySelector('.bulk-reference-request-modal--preflight').innerHTML = "";
    modal.querySelector('.bulk-reference-request-modal--form-fields').innerHTML = "";
  }
  
  #resetFooter() {
    let modal = this.editorModal;
    modal.querySelector('.bulk-reference-request-modal--disabled-preview').classList.add('hidden');
    modal.querySelector('.modal-editor--submit-button').classList.add('hidden');
  }
  
  includeRecentlyNotified(include) {
    this.recipientListItems.filter(el => el.dataset.recentlyNotified === "true").forEach(el => {
      let checkbox = el.querySelector('input[type="checkbox"]');
      checkbox.checked = include;
      checkbox.disabled = !include;
      el.classList.toggle('bulk-reference-request-modal--disallowed', !include);
    });
    this.updateSummary();
  }
  
  updateSummary() {
    this.referenceSummary.count = this.checkedReferenceIds.length;
    this.applicationSummary.count = this.checkedApplicationIds.length;
    this.updatePreviewButton();
  }
  
  updatePreviewButton() {
    let previewButton = this.editorModal.querySelector('.bulk-reference-request-modal--disabled-preview');
    previewButton.disabled = this.checkedReferenceIds.length === 0;
  }
  
  makePreview() {
    this.previewReferenceIds = this.checkedReferenceIds.join(' ');
    this.#updatePreviewRecipients();
    
    'subject body signature'.split(' ').forEach(attribute => {
      let editor = this.editorModal.querySelector(`[name="reference_notification_from_analyst[${attribute}]"]`);
      let preview = this.previewModal.querySelector(`[name="reference_notification_from_analyst[${attribute}]"]`);
      preview.value = editor.value;
    });
  }
  
  #updatePreviewRecipients() {
    let holder = this.previewRecipientsHolder;
    holder.innerText = "";
    
    Components.tag(holder,
      Components.tag("div",
        Components.tag("strong", "To"),
        " ",
        Components.tag("span", this.recipientsSummary.innerText),
      ),
      Components.tag("details",
        Components.tag("summary", "Recipients"),
        Components.tag("ul", {class: 'list-unstyled'}, ...this.checkedReferenceEls.map(r => Components.tag("li", e => {e.innerHTML = r.innerHTML}))),
      ),
    );
  }
  
  static setup() {
    $(document).on('show.bs.modal', '.bulk-reference-request-modal', event => {
      new BulkReferenceRequest().preflight();
    }).on('hidden.bs.modal', '.bulk-reference-request-modal', event => {
      new BulkReferenceRequest().resetEditorModal();
    });
    
    $(document).on('change', '.bulk-records-rundown--action---recent-recipient-filter input', event => {
      new BulkReferenceRequest().includeRecentlyNotified(event.target.checked);
    });
    
    $(document).on('show.bs.modal', '.bulk-reference-request-preview', event => {
      new BulkReferenceRequest().makePreview();
    });
    
    $(document).on('change', '.bulk-reference-request-modal--pick-record', event => {
      new BulkReferenceRequest().updateSummary();
    });
  }
}
