import RecruitmentLevelPicker from 'pickers/recruitment_level_picker';
import Translations from '../../services/translations';

class FakeApplicationsRecruitmentLevelPicker extends RecruitmentLevelPicker {
  templateSelection(state) {
    if (state.id) this.update(state);
    return super.templateSelection(state);
  }

  update(state) {
    let disabled = state.submission_process == "review_windows" ? false : true;
    this.updateSubmittedApplicantsField(disabled);
    this.updateHints(disabled);  
  }

  updateSubmittedApplicantsField(disabled) {
    let submittedApplicantsCountField = document.querySelector("#fake_applications_submitted_applicants_count");
    submittedApplicantsCountField.disabled = disabled;
    if (submittedApplicantsCountField.disabled) submittedApplicantsCountField.value = 0;
  }

  async updateHints(isLegacy) {
    let i18n = await Translations.create(['admin']);
    let submission_process = isLegacy ? 'legacy' : 'review_windows';
    let qualifiedApplicantsCountField = document.querySelector('#fake_applications_meets_basic_applicants_count + .help-block');
    let completeApplicantsCountField = document.querySelector('#fake_applications_complete_applicants_count + .help-block');

    qualifiedApplicantsCountField.textContent = i18n.t(`admin.fake_applications.form.hints.${submission_process}.meets_basic_applicants_count`);
    completeApplicantsCountField.textContent = i18n.t(`admin.fake_applications.form.hints.${submission_process}.complete_applicants_count`);
  }
}

export default {
  setup() {
    FakeApplicationsRecruitmentLevelPicker.watch('select.fake-applications-recruitment-level-picker');
  }
}
