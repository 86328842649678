import Reorder from 'behavior/reorderable';
import Glow from 'behavior/glow';

class DocumentReorder extends Reorder {
  constructor() {
    super('.level-documents-requisites');
  }
  
  get url() {
    return this.el().data('reorder-url');
  }
  
  get data() {
    return {
      file_types: this.el().find('tr:not(.header)').map((ix, tr) => {
        return {id: $(tr).data('file-id'), list_order: ix + 1};
      }).toArray()
    };
  }
  
  sortableOptions() {
    return {
      ...super.sortableOptions(),
      axis: "y",
      items: 'tr.file'
    };
  }
  
  spinner() {
    return this.el().find('.reorder-spinner');
  }
  
  sortStarted(event, ui) {
    ui.placeholder
      .css({height: ui.item.height()})
      .append(
        $('<td>')
          .attr('colspan', ui.item.find('th, td').length)
          .addClass('text-muted')
          .append(Components.iconic('hand-point-left'))
          .append(" Move here")
      );
  }
  
  updateStarted() {
    this.spinner().removeClass('hidden');
  }
  
  updateDone() {
    this.spinner().addClass('hidden');
  }
  
  updateSuccess() {
    new Glow(this.el().find('.header th'), 'Order saved', 'success').animate();
  }
}

export var recruitmentDocumentReordering = new DocumentReorder('.level-documents-requisites');
