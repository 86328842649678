import {ListEditorGroup} from 'components/list_editor';

export default {
  setup() {
    // admin's exemption categories
    new ListEditorGroup({
      form: $('html.admin.exemptions #template-category-list').closest('form'),
      editors: {main: {
        list: $('#template-category-list'),
        itemName: 'category',
        template: $('#category-template'),
        actions: {
          'add-description': function(event, li) {
            li.addClass('has-description');
            li.find('textarea.description').focus();
            return false;
          }
        }
      }},
      willSave: function(json, list) {
        return $.extend(json, {categories: list, show_archived: document.querySelector('.categories--show-archived-toggle').checked});
      },
      didSave: function(group, json, status, xhr) {
        group.editor('main').data(json.categories);
      }
    });

    // admin's waiver categories
    new ListEditorGroup({
      form: $('html.admin.waivers #categories').closest('form'),
      root: $('html.admin.waivers #categories .modal-body'),
      editorDefaults: {
        template: $('#category-template'),
        actions: {
          'add-description': function(event, li) {
            li.addClass('has-description');
            li.find('textarea.description').focus();
            return false;
          }
        }
      },
      newItemTemplates: {
        default: function(editor) {return {name: '', type: editor.name}}
      },
      willSave: function(json, list) {
        return $.extend(json, {categories: list});
      },
      didSave: function(group, json) {
        group.addEditors(json.categories);
      }
    });

    $('#categories').on('will-add.list-editor', function(event, el, model) {
      el.toggleClass('in-use', model.used_by > 0);
      el.toggleClass('has-description', !!model.description);
    }).on('edit.list-editor', function(event, el) {
      $(el).find('.auto-expand').change();
    }).on('unedit.list-editor commit.list-editor', function(event, el) {
      var listEditor = $(event.target).data('listEditor');
      var model = listEditor.model($(el));
      $(el).toggleClass('has-description', !!model.description);
    });
  }
};
