import {Hoverboard} from './hoverboard';

let paths = {};

export class HoverboardPathTraversal {
  constructor({path, id, direction}) {
    this.path = path;
    this.id = id;
    this.delta = direction === 'previous' ? -1 : 1;
  }
  
  get currentIndex() { return this.path.ids.indexOf(this.id); }
  get newIndex() { return this.currentIndex + this.delta; }
  get newId() { return this.path.ids[this.newIndex]; }
}

export class HoverboardPathTraversalLink {
  constructor(element) {
    let data = element.dataset;
    
    this.pathName = data.hoverboardNavigationPath;
    this.current = data.hoverboardNavigationCurrent;
    this.direction = data.hoverboardNavigationDirection;
    
    this.path = HoverboardPath.named(this.pathName);
    this.element = element;
  }
  
  get disabled() { return !this.enabled; }
  get enabled() { return this.path.newId({from: this.current, direction: this.direction}); }
  
  trigger() {
    this.path.moveFrom({from: this.current, direction: this.direction});
  }
  
  setEnabled() {
    $(this.element).prop('disabled', this.disabled);
  }
  
  loadMore() {
    if (this.enabled) { return; }
    
    let loadLinks = document.querySelectorAll(`*[data-hoverboard-navigation-load-${this.direction}="${this.pathName}"]`);
    let loadLink = this.direction === 'previous' ? loadLinks[loadLinks.length - 1] : loadLinks[0];
    if (!loadLink) { return; }
    
    loadLink.click();
  }
}

export default class HoverboardPath {
  constructor(name) {
    this.name = name;
  }
  
  get ids() {
    return Array.prototype.map.call(this.triggers(), el => el.dataset.target);
  }
  
  get progress() {
    let currentIndex = this.currentIndex;
    return currentIndex === -1 ? '' : `${currentIndex + 1} of ${this.pathSize}`;
  }
  
  get currentId() {
    return Hoverboard.current.id;
  }
  
  get currentTrigger() {
    return document.querySelector(`a[data-toggle=hoverboard][data-target="#${this.currentId}"]`);
  }
  
  get currentIndex() {
    let infoRoot = this.infoRoot('start-index');
    let triggers = this.triggers(infoRoot);
    let findTarget = `#${this.currentId}`;
    let indexInRoot = Array.prototype.findIndex.call(triggers, el => el.dataset.target === findTarget);
    return indexInRoot === -1 ? -1 : indexInRoot + Number(infoRoot ? infoRoot.getAttribute(this.attrName('start-index')) : 0);
  }
  
  get pathSize() {
    let infoRoot = this.infoRoot('count-available');
    return Number(infoRoot ? infoRoot.getAttribute(this.attrName('count-available')) : this.ids.length);
  }
  
  triggers(root=null) {
    return (root || document).querySelectorAll(`a[data-hoverboard-navigation-path="${this.name}"]`);
  }
  
  triggerFor({id}) {
    return Array.prototype.find.call(this.triggers(), el => el.dataset.target === id);
  }
  
  newId({from, direction}) {
    return new HoverboardPathTraversal({path: this, id: from, direction}).newId;
  }
  
  moveFrom({from, direction}) {
    let newId = this.newId({from, direction});
    if (newId) {
      Hoverboard.current.hide();
      this.triggerFor({id: newId}).click();
    }
  }
  
  attrName(name) {
    return `data-hoverboard-navigation-path-${this.name}-${name}`
  }
  
  infoRoot(name) {
    return this.currentTrigger.closest(`*[${this.attrName(name)}]`);
  }
  
  static named(name) {
    return paths[name] = paths[name] || new HoverboardPath(name);
  }
  
  static setup() {
    new BusyBody({
      selector: '*[data-hoverboard-navigation-current]',
      added: (el) => new HoverboardPathTraversalLink(el).setEnabled(),
    });
    
    $(document)
      .on('loaded.recruit.hoverboard', '.hoverboard', (event) => {
        event.currentTarget.querySelectorAll('*[data-hoverboard-navigation-current]').forEach(trigger => {
          let link = new HoverboardPathTraversalLink(trigger);
          link.setEnabled();
          link.loadMore();
        });
      })
      .on('loaded.recruit.hoverboard', '.hoverboard', (event) => {
        event.currentTarget.querySelectorAll('*[data-hoverboard-navigation-bind]').forEach(progressIndicator => {
          let path = HoverboardPath.named(progressIndicator.dataset.hoverboardNavigationPath);
          let format = progressIndicator.dataset.hoverboardNavigationBind;
          $(progressIndicator).text(format.replace('${progress}', path.progress));
        });
      })
      .on('click', '*[data-hoverboard-navigation-current]', (event) => {
        let link = new HoverboardPathTraversalLink(event.currentTarget);
        link.trigger();
      });
  }
}
