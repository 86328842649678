export var analystPluralizeRecipients = {
    setup: function() {
        function pluralizeRecipientCount() {
            let recipient_count = document.querySelectorAll('input[name="selected[]"]:checked').length;
            let applicant_string;

            if (recipient_count === 1) {
                applicant_string = 'applicant'
            } else {
                applicant_string = 'applicants'
            }

            document.querySelector("#recipients-qty").innerHTML = recipient_count + " " + applicant_string;
        }

        const recipients = document.querySelectorAll('input[name="selected[]"]');
        for (const recipient of recipients ) {
            recipient.addEventListener('click', pluralizeRecipientCount);
        }
    }
}
