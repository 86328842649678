export class TableTyrantSorting {
  constructor(grid) {
    this._grid = grid;
    this._$list = this._grid._el.find('.sort-selectors');
    this._$itemTemplate = this._$list.find('.sort-selector.template');
    this._rehydrateSorts();
  }
  
  set(sort) {
    var sorts = this;
    this.reset();
    $.each(sort, function() {sorts.add(this)});
  }
  
  reset() {
    this._grid._el.find('input[type=hidden][name=page]').val(1);
    this.#selectors.remove();
    this._listWasUpdated();
  }
  
  add(sort) {
    this.addSelector(sort);
  }
  
  remove(columnName) {
    var sorts = this;
    var $select = this.#selectors.find('select').filter(function() {
      return $(this).val() === columnName;
    });
    if ($select.length === 0) {return false;}
    
    $select.closest('.sort-selector').remove();
    sorts._listWasUpdated();
    return true;
  }
  
  toggle(columnName) {
    var sorts = this;
    var grid = sorts._grid;
    
    if (sorts.remove(columnName)) {
      grid.event('sort-toggle-out', '-' + columnName);
      grid.updateWorkspace();
      return true;
    } else {
      grid.event('sort-toggle-in', '-' + columnName);
      return false;
    }
  }
  
  get columns() { return this._grid._el.find('input[name="sort"]')?.val()?.split(',') || [] }
  get directions() { return this._grid._el.find('input[name="sort-dir"]').val().split(',') }
  
  addSelector(opts) {
    var sorts = this;

    var $sel = sorts._makeSelector($.extend({}, {sortDirection: 'ascending'}, opts));
    this._disablePickedColumns($sel);
    sorts._$list.append($sel);
    
    sorts._listWasUpdated();
  }
  
  addSelectorForDefault() {
    var columnName = this._$list.attr('data-default-sort');
    if (!columnName) {return}
    
    this.addSelector({
      columnName: columnName,
      sortDirection: this._$list.attr('data-default-sort-dir')
    });
  }
  
  setColumnIn($sel, columnName) {
    $sel.find('.sort-column-picker').val(columnName);
    return $sel;
  }
  
  setDirectionIn($sel, direction) {
    var $sortDir = $sel.find('.sort-dir');
    $sortDir.find('label').removeClass('active');
    
    var input = $sortDir.find('input[value="' + direction + '"]');
    input.prop('checked', true);
    input.closest('label').addClass('active');
    return $sel;
  }
  
  fillSelector($sel, opts) {
    this.setColumnIn($sel, opts.columnName);
    this.setDirectionIn($sel, opts.sortDirection || 'ascending');
    return $sel;
  }
  
  _makeSelector(opts) {
    this._$itemTemplate.find("select.select2-hidden-accessible").each(function (){$(this).select2("destroy");});
    var $sel = this._$itemTemplate.clone(true).removeClass('hidden template');
    $sel.find('.sort-dir input').attr('name', 'sort-direction--' + Math.random());
    return this.fillSelector($sel, opts);
  }
  
  get #selectors() { return this._$list.find('.sort-selector:not(.template)'); }
  
  _rehydrateSorts() {
    var sorts = this;
    sorts._$list.find('.sort-selector.dehydrated').each(function() {
      var $dehydrated = $(this);
      $dehydrated.replaceWith(sorts._makeSelector($dehydrated.data()));
    });
    sorts._listWasUpdated();
  }
  
  _makeDraggable() {
    this._grid._el.find('.sort-selectors').sortable({
      axis: "y",
      cursor: 'move',
      handle: '.grip',
      opacity: .75,
      placeholder: 'sorting-placeholder',
      update: (event, ui) => {this._listWasUpdated()}
    });
  }
  
  _setup() {
    this._addListeners();
    this._makeDraggable();
  }
  
  _addSortRequest(event) {
    var sorts = this;
    var grid = sorts._grid;
    
    var $th = $(event.target).closest('th');
    var columnName = $th.attr('data-sort');
    var newDirection = $th.is('.sort-ascending') ? 'descending' : 'ascending';
    if (event.shiftKey) {
      // don't select random bits of page text
      document.getSelection().removeAllRanges();
      
      if (sorts.toggle(columnName)) return;
    } else {
      grid.event('sort-' + newDirection, '-' + columnName);
      sorts.set([]);
    }
    
    sorts.add({
      columnName: columnName,
      sortDirection: newDirection
    });
    grid.updateWorkspace();
  }
  
  _addListeners() {
    var sorts = this;
    var $gridEl = sorts._grid._el;
    
    $gridEl.on('click', 'thead tr.section-headers th:not(.unsortable)', function(event) {
      var $target = $(event.target);
      if ($target.is(':input, a')) return;
      
      event.preventDefault();
      event.stopPropagation();
      sorts._addSortRequest(event);
    }).on('change', '.sort-selector .sort-column-picker', function(event) {
      setTimeout(function() {sorts._pullColumns(); sorts._disablePickedColumns()}, 0);
    }).on('change', '.sort-selector .sort-dir input', function(event) {
      setTimeout(function() {sorts._pullDirections();}, 0);
    }).on('click', '.js-sorts-add', function(event) {
      event.preventDefault();
      sorts.addSelector();
    }).on('click', '.js-sorts-reset', function(event) {
      event.preventDefault();
      sorts.reset();
      sorts.addSelectorForDefault();
    }).on('click', '.js-sort-remove', function(event) {
      event.preventDefault();
      $(event.target).closest('.sort-selector').remove();
      sorts._listWasUpdated();
    });
  }
  
  _listWasUpdated() {
    var sorts = this;
    sorts._$list.find('.zero-state')
      .toggleClass('hidden', sorts.#selectors.length > 0);
  
    sorts._pullColumns();
    sorts._pullDirections();
    sorts._disablePickedColumns();
  }
  
  _pullColumns() {
    var cols = this.#selectors.find('.sort-column-picker').map(function() {return $(this).val()});
    this._grid._el.find('input[name="sort"]')?.val(TableTyrant.Sorts._asCommaList(cols));
  }
  
  _pullDirections() {
    var dirs = this.#selectors.find('.sort-dir input:checked').map(function() {return this.value});
    this._grid._el.find('input[name="sort-dir"]').val(TableTyrant.Sorts._asCommaList(dirs).replace(/ending/g, ''));
  }
  
  _disablePickedColumns($sels) {
    $sels = ($sels || this.#selectors).find('select');
    
    // turn them all on
    $sels.find('option').prop('disabled', false);
    
    // turn off the ones that are on elsewhere
    let cols = this.columns;
    $sels
      .find('option:not(:checked)')
      .filter((ix, el) => cols.includes(el.value))
      .prop('disabled', true);
    
    // let select2 know that the options have changed
    $sels.each(function() {$(this).select2($(this).data('original-picker-opts'))});
  }
  
  static _asCommaList(list) {
      if ('toArray' in list) {list = list.toArray()}
      if ($.isArray(list)) {list = list.join(',');}
      return list;
  }
}
