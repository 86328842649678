import Glow from 'behavior/glow';

export var adminApprovalPermissions= {
  setup() {
    let modal = $('#approval-permissions');
    let link = modal.closest('form').attr('action');
    let rolesContainer = document.querySelector('#role-permissions-container');
    let rolesSelector = $('.approver-role-picker');

    rolesSelector.on('select2:select select2:unselect', (e) => { this.toggleRoleFieldset(e, rolesContainer); });
    modal.on('shown.bs.modal', () => { this.loadPermissionCheckBoxes(rolesContainer, rolesSelector, link); });
    modal.on('hidden.bs.modal', () => {
      rolesSelector.val(null).trigger('change');
      this.hideAllRoleFieldsets(rolesContainer);
    })
  },

  updateCheckbox(permissionName, add, assign) {
    let addCheckbox = document.querySelector(`input[name="approval_permissions_form[add_${permissionName}]"]:not([type="hidden"])`);
    addCheckbox.checked = add;
    let assignCheckbox = document.querySelector(`input[name="approval_permissions_form[assign_${permissionName}]"]:not([type="hidden"])`);
    assignCheckbox.checked = assign;
  },

  hideAllRoleFieldsets(container) {
    for (let fieldset of container.children) $(fieldset).hide();
    new Telescope($('#restricted-roles')).val('hide-settings');
  },

  toggleRoleFieldset(event, container) {
    let type = event.params._type;
    let roleIdx = event.params.data.element.value;
    let fieldset = container.children[roleIdx];

    if (type === "select") {
      $(fieldset).show();
      new Glow($(fieldset), "", "success").animate();
    }
    else if (type === "unselect") {
      $(fieldset).hide(); 
    }
  },

  loadPermissionCheckBoxes(container, selector, link) {
    new Telescope($('#restricted-roles')).val('hide-settings');

    fetch(link)
      .then(response => response.json())
      .then(roles => {
        let valuesToSelect = [];
        Object.values(roles).forEach((role, index) => {
          this.updateCheckbox(role.permission_name, role.add, role.assign);
          if (!role.add || !role.assign) valuesToSelect.push(index);
        });
        
        new Telescope($('#restricted-roles')).val('show-settings');
        selector.val(valuesToSelect).trigger('change');
        valuesToSelect.forEach( (index) => {
          let fieldset = container.children[index];
          $(fieldset).show();
        });
      })	
  }
}
