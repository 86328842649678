export var surveySurveyForm = {
  setup() {
    this.setupHispanicLatinoHierarchy();
    this.setupOtherEthnicitiesHierarchy();
  },
  
  setupHispanicLatinoHierarchy() {
    let hispanicOrLatinoEthnicities = document.querySelector('#hispanic-or-latino');
    let yesIAmHispanicOrLatino = hispanicOrLatinoEthnicities.querySelector('#survey_answer_hispanic_or_latino_o');

    hispanicOrLatinoEthnicities.addEventListener('change', (event) => {
      if (event.target.matches('#survey_answer_hispanic_or_latino_not_hispanic_or_latino') || event.target.matches('#survey_answer_hispanic_or_latino_decline_to_state_hispanic')) {
        hispanicOrLatinoEthnicities.querySelectorAll('input[type=checkbox]').forEach((checkbox) => {
          checkbox.checked = false;
        });
      }
      
      if (event.target !== yesIAmHispanicOrLatino) {
        yesIAmHispanicOrLatino.checked = hispanicOrLatinoEthnicities.querySelectorAll('input[type=checkbox]:checked').length > 0;
      }
    });
  },
  
  setupOtherEthnicitiesHierarchy() {
    let otherEthnicities = document.querySelector('#ethnicity');
    let declineToStateOther = otherEthnicities.querySelector('#survey_answer_ethnicity_decline_to_state_other');

    otherEthnicities.addEventListener('change', (event) => {
      if (event.target.matches('#survey_answer_ethnicity_decline_to_state_other')) {
        otherEthnicities.querySelectorAll('input[type=checkbox]').forEach((checkbox) => {
          checkbox.checked = false;
        });
      } else if (event.target.checked) { // if the applicant checks an ethnicity, uncheck decline to state
        declineToStateOther.checked = otherEthnicities.querySelectorAll('input:checked').length === 0;
      }
    });
  }
}
