export var formRequiredInputs = {
  setup() {
    $(document)
      .on('keyup change', 'form[data-required-inputs]', function(event) {
        var $form = $(this);
        $(enableTarget($form)).prop("disabled", !allInputsPresent(required($form)));
      });
    $('form[data-required-inputs]').trigger("keyup");

    function allInputsPresent(inputs) {
      for (var i in inputs) {
        if (inputs[i].includes("selector")) {
          let selector_count = $('input[name="selected[]"]:checked').length;
          if (selector_count === 0) {return false;}
          continue;
        }
        var value = $(inputs[i]).val()
        if(value === null || value.length === 0) {return false;}
      }
      return true
    }

    function required($form) {
      return $form.data('required-inputs').split(",");
    }

    function enableTarget($form) {
      return $form.data('required-inputs-target');
    }
  }
};
