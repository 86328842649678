import {FindAndUpdate} from 'services/find_and_update';

class BulkRedactionModal {
  constructor(modal) {
    this.modal = modal;
  }

  static closest(el) {
    return new BulkRedactionModal(el.closest('.redact-file--bulk'));
  }

  get preflightLink() {
    return this.modal.querySelector('a.redact-file--bulk-preflight-trigger');
  }

  get preflight() {
    return this.modal.querySelector('.redact-file--bulk-preflight');
  }

  get form() {
    return this.modal.closest('form');
  }

  get applicationIds() {
    return $(this.form).selectedCollector().values;
  }

  fetchPreflight() {
    let link = this.preflightLink;
    link.dataset.params = new URLSearchParams({selected: this.applicationIds.join(',')}).toString();
    link.click();
  }

  resetPreflight() {
    this.preflightLink.classList.remove('hidden');
    this.preflight.classList.add('hidden');
  }

  setupButtons() {
    let submitButton = this.modal.querySelector('.redact-file--bulk-submit');

    this.modal.addEventListener('click', (event) => {
      if (event.target.matches("input[type=radio]")) {
        submitButton.disabled = false;
      }
    });
  }

  setupHoverboard() {
    const formData = new FormData(this.form);
    formData.append('selected', this.applicationIds);
    const recruitmentId = this.modal.dataset.recruitment;
    const token = document.querySelector('meta[name="csrf-token"]');
    const path = `/analyst/recruitments/${recruitmentId}/applications/bulk_redactions/hoverboard_content.json`;
    fetch(path, {
      method: 'PUT',
      headers: {'X-CSRF-Token': (token ? token.content : null)},
      body: formData
    })
      .then(response => response.json())
      .then(json => FindAndUpdate.all(json));
  }
}

export var applicationBulkRedactionModal = {
  setup: function() {
    $(document).on('show.bs.modal', '.redact-file--bulk', event => {
      let modal = new BulkRedactionModal(event.target);
      modal.fetchPreflight();
    }).on('hidden.bs.modal', '.redact-file--bulk', event => {
      let modal = new BulkRedactionModal(event.target);
      event.target.querySelector('.redact-file--bulk-submit').classList.add('hidden');
      event.target.querySelector('.bulk-redaction-modal--disabled-save').classList.remove('hidden');
      modal.resetPreflight();
    }).on('ajax:success', '.redact-file--bulk', event => {
      let modal = new BulkRedactionModal.closest(event.target);
      modal.setupButtons();
    }).on('shown.recruit.hoverboard', '#bulk-redaction-hoverboard', event => {
      let modal = new BulkRedactionModal.closest(document.querySelector(".redact-file--bulk"));
      modal.setupHoverboard();
    });
  }
};
