export class TableTyrantFilters {
  constructor(grid) {
    this._grid = grid;
  }
  
  reset() {
    var form = this._grid.form;
    form.find('.filter-group.disengaged[data-engaged-by-default=true] .engage-trigger').click();
    form.find('.filter-group.engaged[data-engaged-by-default=false] .disengage-trigger').click();
    form.find('.filter-group .option[data-enabled-by-default=true] input[type=checkbox]').prop('checked', true);
    form.find('.filter-group .option[data-enabled-by-default=false] input[type=checkbox]').prop('checked', false);
  }
  
  search(q) {
    new DynamicSearch(this._grid._el.find('.modal .filter-group').toArray()).search(q.toLowerCase());
  }
  
  group(name) {
    var groupEl = this._grid._el.find('.filter-group[data-filter-group-name="' + name + '"]');
    return new TableTyrant.Filters.Group(groupEl);
  }
  
  groups(fn) {
    this._grid._el.find('.filter-group').each(function() {
      fn(new TableTyrant.Filters.Group($(this)));
    });
  }
}
