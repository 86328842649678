export default class ExemptionComputedSalary {
  constructor(root) {
    this.root = root;
  }
  
  get id() { return this.root.id; }
  
  get percentTimeInput() { return this.root.querySelector('.percent-time input'); }
  get percentTime() { return Number(this.percentTimeInput.value); }
  
  get salariedInput() { return this.root.querySelector('.has_salary input[value="true"]'); }
  get withoutSalaryInput() { return this.root.querySelector('.has_salary input[value="false"]'); }
  get hasSalary() { return this.salariedInput.checked || this.fullTimeSalaryInput.value !== ""; }
  
  get fullTimeSalaryFieldGroup() { return this.root.querySelector('.form-group.salary'); }
  get fullTimeSalaryInput() { return this.root.querySelector('.form-group.salary input'); }
  get fullTimeSalary() {
    let value = this.root.querySelector('.salary input').value;
    return parseFloat(value && value.replace ? value.replace(/,/g, '') : value).toFixed(2);
  }
  
  get computedSalaryFieldGroup() { return this.root.querySelector('.form-group.computed-salary'); }
  get computedSalaryInput() { return this.root.querySelector('.form-group.computed-salary input'); }
  get computedSalary() { return this.percentTime / 100.0 * this.fullTimeSalary; }
  set computedSalary(value) {
    if (value === 0 || isNaN(value)) {
      analytics.event('appointment-computed-salary', 'hide', this.id);
      this.computedSalaryFieldGroup.classList.add('hidden');
    } else {
      analytics.event('appointment-computed-salary', 'show', this.id, value);
      this.computedSalaryFieldGroup.classList.remove('hidden');
      this.computedSalaryInput.value = value.toFixed(value.toFixed(2).match(".00") ? 0 : 2);
    }
  }
  
  updateComputedSalary() {
    this.computedSalary = this.computedSalary;
  }
  
  hideShow(value=this.hasSalary) {
    if (value) {
      analytics.event('appointment-salary', 'show', this.id);
      this.fullTimeSalaryFieldGroup.classList.remove('hidden');
    } else {
      analytics.event('appointment-salary', 'hide', this.id);
      this.fullTimeSalaryFieldGroup.classList.add('hidden');
      this.fullTimeSalaryInput.value = '';
      this.fullTimeSalaryInput.dispatchEvent(new Event("change"));
    }
  }
  
  setup() {
    this.updateComputedSalary();
    [this.percentTimeInput, this.fullTimeSalaryInput].forEach(el => {
      el.addEventListener('keyup', e => this.updateComputedSalary());
      el.addEventListener('change', e => this.updateComputedSalary());
    });
    
    this.hideShow();
    [this.salariedInput, this.withoutSalaryInput].forEach(el => {
      el.addEventListener('change', e => this.hideShow(el.value === "true"));
      el.addEventListener('click', e => this.hideShow(el.value === "true"));
    });
  }
  
  static setup() {
    new BusyBody({
      selector: 'fieldset.appointments .appointment',
      added: (el) => new ExemptionComputedSalary(el).setup(),
    });
  }
};
