import BusyBody from "../../services/busy_body";

class Levels {
  constructor(holder = document) {
    this.holder = holder;
  }

  static closest(el) {
    return new Levels(el.closest('.js-levels'));
  }

  noticeNameChanges() {
    this._levels().forEach((level) => {
      let name = level.querySelector('.js-name-field').value;
      level.querySelectorAll('[data-bind=name]').forEach((levelTitle) => {
        levelTitle.textContent = name;
      });
    });
  }

  add() {
    let template = this._template();
    let newLevelFields = template.copy({
      reindex: {
        index: this._levels().length,
        groupName: 'levels',
        groupBy: '.level',
        groupCallback: (el, ix) => {
          el.querySelector('legend .number').textContent = ix + 1;
          el.querySelector('.title-code-picker').dataset.dynamicCategoriesTarget = "#level-" + (ix + 1) + "-category";
          el.querySelector('dl.title-categories').id = "level-" + (ix + 1) + "-category";
        }
      }
    });

    this.holder.querySelector('.levels').append(newLevelFields);
  }

  _levels() {
    return this.holder.querySelectorAll('.level');
  }

  _template() {
    let templateEl = this._levels()[0];
    return new Template(templateEl);
  }
}

export var recruitmentLevels = {
  setup() {
    const titleLevelModalSelector = '#title-and-level-information';

    new BusyBody({
      selector: titleLevelModalSelector,
      added: () => this.eventListenerOn(
        'keyup',
        document.querySelector(titleLevelModalSelector),
        '.js-name-field',
        e => Levels.closest(e.target).noticeNameChanges()
      )
    });

    new BusyBody({
      selector: titleLevelModalSelector,
      added: () => this.eventListenerOn(
        'click',
        document.querySelector(titleLevelModalSelector),
        '.add-level',
        e => Levels.closest(e.target).add()
      )
    });
  },

  eventListenerOn(event, baseEl, selector, callback) {
    baseEl.addEventListener(event, (e) => {
      let closest = e.target.closest(selector);
      if (closest && baseEl.contains(closest)) {
        callback(e);
      }
    });
  }
}
