export var recruitmentSearchReportWarnings = {
  toggleSubmitByReviewDate(rdPicker) {
    let modal = rdPicker.closest('.modal');
    let reviewDateId = rdPicker.value;
    let todo = modal.querySelector(`.todo-ocf, .todo-rw[data-review-date-id="${reviewDateId}"]`);
    let submit = modal.querySelector('.modal-footer button');

    submit.disabled = submit.classList.contains('always-disabled') || todo;
  },

  toggleSubmitByBatchRound(brPicker) {
    let modal = brPicker.closest('.modal');
    let batchRoundId = brPicker.value;
    let todo = modal.querySelector(`.todo-br[data-batch-round-id="${batchRoundId}"]`);
    let submit = modal.querySelector('.modal-footer button');

    submit.disabled = submit.classList.contains('always-disabled') || todo;
  },

  setup() {
    $(document).on('change', '.search-report-modal--review-date-picker', function(event) {
      recruitmentSearchReportWarnings.toggleSubmitByReviewDate(event.target);
    });

    document.querySelectorAll('.search-report-modal--review-date-picker')
      .forEach(picker => recruitmentSearchReportWarnings.toggleSubmitByReviewDate(picker));

    $(document).on("change", ".search-report-modal--batch-round-picker", function(event) {
      recruitmentSearchReportWarnings.toggleSubmitByBatchRound(event.target);
    });

    document.querySelectorAll('.search-report-modal--batch-round-picker')
      .forEach(picker => recruitmentSearchReportWarnings.toggleSubmitByBatchRound(picker));
  }
};
