import Translations from '../../services/translations';

export var adminI18nextTest = {
  async loadTranslations() {
    // Pass either a single namespace or a list of namespaces when getting i18n translations
    let i18n = await Translations.create(['analyst', 'admin', 'campus_location']);
    
    let example1Elem = document.querySelector('#example1');
    let example2Elem = document.querySelector('#example2');
    let example3Elem = document.querySelector('#example3');
    let example4Elem = document.querySelector('#example4');
    let example5Elem = document.querySelector('#example5');

    // Example of Translation
    example1Elem.innerHTML = i18n.t('analyst.search_sources.create.failure_title')

    // Example of Translation with Interpolation
    example2Elem.innerHTML = i18n.t('analyst.approvals.create.duplicate', {whatisit: '**js variable**'});

    // Example of Translation from a different namespace
    example3Elem.innerHTML = i18n.t('admin.users.table_tyrant.bulk.confirm.warning.title')

    // Example of Campus Specific Translation: 'campus_location'
    example4Elem.innerHTML = i18n.t('campus_location');

    // Example of Missing Translation: 'admin.a_missing_translation'
    example5Elem.innerHTML = i18n.t('admin.a_missing_translation');
  },

  async setup() {
    await this.loadTranslations();

    const reloadLink = document.querySelector("#i18next .tab-title .i18next-reload");
    reloadLink.addEventListener("click", (e) => {
      e.preventDefault();

      fetch("/admin/pattern_library/i18next.json")
      .then(async () => {
        this.loadTranslations();
      });
    });
  },
}
