import {ListEditorGroup} from 'components/list_editor';

export default {
  setup() {
    new BusyBody({
      selector: "#qualified",
      added: (el) => new ListEditorGroup({
        form: $('#qualified-custom-reasons-editor').closest('form'),
        editors: {main: {
            list: $('#qualified-custom-reasons-editor .modal-content .reasons'),
            itemName: 'qualified-reason',
            template: $('#qualified-reason-template')
          }},
        willSave: function(json, list) {
          return $.extend(json, {reasons: list});
        },
        didSave: function(group, json, status, xhr) {
          group.editor('main').data(json.reasons);
        }
      }),
    });

    new BusyBody({
      selector: '#unqualified',
      added: (el) => new ListEditorGroup({
        form: $('#unqualified-custom-reasons-editor').closest('form'),
        editors: {main: {
            list: $('#unqualified-custom-reasons-editor .modal-content .reasons'),
            itemName: 'unqualified-reason',
            template: $('#unqualified-reason-template')
          }},
        willSave: function(json, list) {
          return $.extend(json, {reasons: list});
        },
        didSave: function(group, json, status, xhr) {
          group.editor('main').data(json.reasons);
        }
      })
    });
    
    $('#disposition_reasons .reasons').on('will-add.list-editor', function(event, el, model) {
      el.toggleClass('in-use', model.in_use == true);
    });
  }
};
