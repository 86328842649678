export var recruitmentAdEvidencesNeedAdSources = {
  setup() {
    $(document).on('show.bs.modal', '.ad-evidences .modal', function() {
      let sourceCount = $('#ad-sources li.ad_source').length;
      let dea = new DynamicErrorArea(this);
      
      if (sourceCount > 0) {
        dea.clear();
      } else {
        dea.fill({
          error_message: 'You are not yet ready to provide evidence.',
          field_errors: {
            ad_source_id: ['Please specify ad sources before providing evidence.']
          }
        });
      }
    });
  }
};
