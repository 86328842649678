import {Telescope} from 'behavior/telescope';
import {ListEditor, ListEditorGroup} from 'components/list_editor';

export default {
  setup() {
    new ListEditorGroup({
      form: $('#specializations-list').closest('form'),
      editors: {main: {
        list: $('#specializations-list'),
        itemName: 'specialization',
        template: $('#specialization-template'),
        actions: {
          'add-ams': function(event, $li, $trigger) {
            $("#specializations-format").find(':input[value="ranked_list"]').parent().click();
            this.clear();
            this.commit(this.add(this.itemTemplate($trigger)));
          }
        }
      }},
      willSave: function(json, list) {
        return $.extend(json, {specializations: list});
      },
      didSave: function(group, json, status, xhr) {
        group.editor('main').data(json.specializations);
        new Telescope($('#edit-specializations')).val('undoomed');
      }
    });
  
    $('#specializations-list').on('will-add.list-editor', function(event, el, model) {
      el.toggleClass('in-use', model.used_by > 0);
    });
  }
};
