class EmailDetailsModal {
  constructor(data) {
    this.data = data;
    this.fieldLabels = {
      emailTo: 'To',
      emailCc: 'CC',
      emailFrom: 'From',
      emailReplyTo: 'Reply-To',
      emailStatus: 'Status',
      emailSentAt: 'Sent at',
      emailCreatedAt: 'Created at',
      emailMessage: 'Message'
    }
  }
  
  show() {
    new Components.Modal({
      title: this.data.emailSubject || 'Email detail',
      id: 'email-detail',
      class: 'no-autofocus',
      body: ((...args) => this._fill(...args)),
      buttons: [
        Components.Modal.okButton(null, {class: 'btn-primary'})
      ]
    }).show();
  }
  
  _fill(body) {
    let dl = $('<dl>').addClass('dl-horizontal tight tightish').appendTo(body);
    
    for (let [fieldName, fieldLabel] of Object.entries(this.fieldLabels)) {
      let value = this.data[fieldName];
      
      if (value && value.length > 0 && !['emailMessage', 'emailHtmlMessage'].includes(fieldName)) {
        $('<dt>').text(`${fieldLabel}:`).appendTo(dl);
        $('<dd>').text(value).appendTo(dl);
      }
    }
    
    this._fillEmailBody(body);
  }

  _fillEmailBody(body) {
    if (this.data.emailHtmlMessage) {
      let htmlframe = $('<iframe class="html-email-body">').appendTo(body);
      htmlframe.attr('srcdoc', this.data.emailHtmlMessage);
    } else {
      let well = $('<div class="well">').appendTo(body);
      this.data.emailMessage.split("\n").forEach(p => $('<p>').text(p).appendTo(well));
    }
  }
}

export var emailShowDetail = {
  setup: function() {
    $(document).on("click", ".email-detail", function(event) {
      new EmailDetailsModal($(event.target).data()).show();
    });
  }
};
