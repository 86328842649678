import {ListEditorGroup} from 'components/list_editor';

export default {
  setup() {
    new ListEditorGroup({
      form: $('#candidate-email-addresses-list').closest('form'),
      editors: {main: {
        list: $('#candidate-email-addresses-list'),
        itemName: 'email address',
        itemSeparator: /(?:[,;]|\s)/,
        template: $('#candidate-email-address-template')
      }},
      willSave: function(json, list) {
        return $.extend(json, {email_addresses: list});
      },
      didSave: function(group, json, status, xhr) {
        group.editor('main').data(json.email_addresses);
      }
    });
  }
};
