export var adminApprovalWorkflowEditorSteps = {

  setup() {
    const stepsContainer = document.querySelector('#steps-container');
    const addStepModal = document.querySelector('#modal-add-approval-workflow-step');
    const stepPicker = addStepModal.querySelector('.approver-role-picker');
    const addStepBtn = addStepModal.querySelector('#addStepBtn');

    // when the dialog is open, return should add the step but not submit the form
    addStepModal.addEventListener('keydown', (event) => {
      if (event.key !== "Enter") return;

      const modal = $(event.target).closest('.modal');
      addStepBtn.click();
      modal.modal('hide');

      let dropdownMenu = document.querySelector('.select2-container.select2-container--default.select2-container--open:not(.select2)');
      if (dropdownMenu !== null) dropdownMenu.remove();
    });

    // Keeps track of all non-deleted steps currently in workflow
    let roleIds = new Set();
    document.querySelectorAll('#steps-container li.step').forEach(step => {
      if (step.querySelector("input[name='steps[][deleted]']").value !== 'true') {
        let roleIdInput = step.querySelector("input[name='steps[][role_id]']");
        roleIds.add(roleIdInput.value);
      }
    })

    addStepBtn.addEventListener('click', () => {
      const roleId = stepPicker.value;
      const roleName = stepPicker.selectedOptions[0].innerHTML;
      const roleRequired = addStepModal.querySelector("input[type=checkbox][name=approval_workflow_step_required").checked;
      const rolePosition = stepsContainer.querySelectorAll("li.step").length - stepsContainer.querySelectorAll("li.step input[name*='deleted'][value=true]").length + 1;
      let stepElement;
      if (roleId !== '') {
        let oldSteps = stepsContainer.querySelectorAll(`li.step input[name="steps[][role_id]"][value="${roleId}"]`);
        if (oldSteps.length > 0) {
          let oldStepElement = oldSteps[0].parentNode;
          let oldStepIdInput = oldStepElement.querySelector('input[name="steps[][id]"');
          let id = oldStepIdInput ? oldStepIdInput.value : null;
          oldSteps.forEach(step => {
            step.parentNode.remove();
          })
          stepElement = this.createStepElement(roleId, roleName, roleRequired, rolePosition, id);
        } else {
          roleIds.add(roleId);
          stepElement = this.createStepElement(roleId, roleName, roleRequired, rolePosition);
        }
      }

      stepsContainer.appendChild(stepElement);
      $(stepPicker).val(null).trigger('change').trigger('select2:select');
    })

    $(stepPicker).on('select2:select', () => {
      const roleId = stepPicker.value;
      const roleName = stepPicker.selectedOptions[0].innerHTML;

      if (roleIds.has(roleId)) {
        addStepModal.querySelector('#duplicate-step-warning .alert-content span.role').innerHTML = roleName;
        $('#duplicate-step-warning').slideDown();
      } else {
        $('#duplicate-step-warning').slideUp();
      }
    })

    $('#steps-container').sortable({
      axis: 'y',
      cursor: 'move',
      handle: '.grip',
      opacity: .75,
      stop: this.repositionSteps
    })

    // Delete Step
    stepsContainer.addEventListener('click', (event) => {
      if (event.target.closest('span.fa-times-circle.fa-solid')) {
        let stepElement = event.target.closest('li.step');
        let stepRoleId = stepElement.querySelector('input[name="steps[][role_id]"]').value;
        let stepPositionInput = stepElement.querySelector('input[name="steps[][position]"]');
        let stepDeletedInput = stepElement.querySelector('input[name="steps[][deleted]"]');
        let deleteResponse = confirm("Are you sure you want to delete this step?");
        if (deleteResponse) {
          roleIds.delete(stepRoleId);
          stepElement.style.display = 'none';
          stepPositionInput.value = 0;
          stepDeletedInput.value = true;
        }
      }
    })
  },

  createStepElement(roleId, roleName, roleRequired, rolePosition, id = null) {
    const stepAttributeMarkup = this.createStepAttributeMarkup(roleRequired);
    const idField = `<input type="hidden" name="steps[][id]" value="${id}">`

    let listElement = document.createElement('LI');
    listElement.classList.add('step');
    listElement.innerHTML = `
      <span class="iconic fa-bars fa-solid grip"></span>
      <span>
        ${roleName}
        <span class="iconic fa-times-circle fa-solid"></span>
      </span>

      ${stepAttributeMarkup}

      ${id ? idField : ''}
      <input type="hidden" name="steps[][role_id]" value="${roleId}">
      <input type="hidden" name="steps[][required]" value="${roleRequired}">
      <input type="hidden" name="steps[][position]" value="${rolePosition}">
      <input type="hidden" name="steps[][deleted]">
    `
    return listElement;
  },

  createStepAttributeMarkup(roleRequired) {
    if (roleRequired) {
      return `
      <div class="attributes">
        <p><span class="iconic fa-asterisk fa-solid"></span>  Required</p>
      </div>
      `
    } else {
      return `
      <div class="attributes">
        <p>Optional</p>
      </div>
      `
    }
  },

  repositionSteps() {
    const steps = document.querySelectorAll('#steps-container .step');

    for (let pos = 0; pos < steps.length; pos++) {
      let stepPosition = steps[pos].querySelector('input[name="steps[][position]"]')
      stepPosition.value = pos + 1;
    }
  }
};
