export var applicationFileReuse = {
  showRecyclables($modal) {
    $modal.find(".library-shelf---recycle li").toggleClass("hidden");
  },
  
  setup() {
    $(document).on('click', '.js-show-recyclables', event => {
      this.showRecyclables($(event.target).closest('.modal'));
    });
  }
};
