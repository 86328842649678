export default class Occluded {
  constructor(el) {
    this.el = el;
  }
  
  setup() {
    if (this.el.classList.contains('occluded---enabled')) { return }
    
    let occlude = this.el.scrollHeight >= 150;
    this.el.classList.toggle('occluded', occlude);
    
    if (!occlude) { return }
    
    this.el.classList.add('occluded---enabled');
    this.el.setAttribute('data-linked-blocker', true);
    this.addLinks();
  }
  
  addLinks() {
    this.el.appendChild(this.showLink('more', () => this.showMore()));
    
    if (!this.el.matches('.occluded---one-way')) {
      this.el.appendChild(this.showLink('less', () => this.showLess()));
    }
  }
  
  showLink(text, listener) {
    return Components.tag('a', `Show ${text}`,
      {href: '#', class: `occluded--show-${text} js-noop`},
      e => e.addEventListener('click', listener)
    );
  }
  
  showMore() {
    this.el.classList.add('occluded---disabled');
  }
  
  showLess() {
    this.el.classList.remove('occluded---disabled');
  }
  
  static get autoloader() {
    return new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          new Occluded(entry.target).setup();
          observer.unobserve(entry.target);
        }
      });
    });
  }
  
  static setup() {
    new BusyBody({
      selector: '.occluded',
      added: (el) => el.offsetParent === null ? Occluded.autoloader.observe(el) : new Occluded(el).setup()
    });
  }
}
