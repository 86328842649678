export class StagingGrid {
  #el

  constructor(el) {
    this.#el = el;
  }

  get allPanels() { return this.#el.find('.item-selector') }
  get stagedPanel() { return this.#el.find('.item-selector--staged') }
  get unstagedPanel() { return this.#el.find('.item-selector--unstaged') }

  static closest(from) {
    return new StagingGrid($(from).closest('.staging-grid'));
  }

  setup() {
    this.#setupItemsSearch();
    this.#setupItemsReset();
    this.#setupItemsStageAll();
    this.#setupItemsKeyboardControl();
    this.#setupItemVisibilityToggle();
    this.#setupItemSortability();
    this.#updateLabels();
  }

  reset() {
    this.allPanels.find('.item[data-item-default-index]').appendTo(this.stagedPanel.find('ol'));
    this.allPanels.find('.item:not([data-item-default-index])').appendTo(this.unstagedPanel.find('ol'));
    this.#sortListByDefaultOrder(this.stagedPanel);
    this.#sortList(this.unstagedPanel);
    this.updateOrder();
  }

  stageAll() {
    this.allPanels.find('.item').appendTo(this.stagedPanel.find('ol'));
    this.#sortListByDefaultOrder(this.stagedPanel);
    this.#sortList(this.unstagedPanel);
    this.updateOrder();
  }

  search(q) {
    let staged = new DynamicSearch(this.stagedPanel.find('*[data-search]').toArray(), {missClass: 'text-muted'});
    staged.search(q.toLowerCase());
    
    let unstaged = new DynamicSearch(this.unstagedPanel.find('*[data-search]').toArray());
    let {matched, missed} = unstaged.search(q.toLowerCase());
    
    // hide groups where the only items that matched are already in the staged panel
    $.each(this.unstagedPanel.find('.item-group').get().reverse(), function() {
      $(this).toggleClass('hidden', !$(this).nextAll('.item:not(.hidden), .item-group:not(.hidden)').first().is('.item'));
    });
    this.unstagedPanel.find('.zero-state').toggleClass('shown', matched.length === 0);
  }

  setOrder(items) {
    var $list = this.stagedPanel.find('ol');
    var $items = $list.children('li.item').get();
    $items.sort(function(a, b) {
       var ixA = items.indexOf($(a).attr('data-item-name'));
       var ixB = items.indexOf($(b).attr('data-item-name'));
       return (ixA < ixB) ? -1 : (ixA > ixB) ? 1 : 0;
    });
    $.each($items, function(ix, item) { $list.append(item); });
    this.updateOrder();
  }

  updateOrder() {
    var items = this.stagedPanel.find('.item').map(function() {return $(this).data('item-name')});
    var stagedItemsInput = this.#el.find('input.staged-items[type=hidden]');
    stagedItemsInput.val(items.toArray().join(","));
    stagedItemsInput[0].dispatchEvent(new Event('change'));
    this.#updateLabels();
  }

  makeStaged(el) {
    el = ("string" !== typeof el) ? el : this.#findItemWithHeader(el);
    if (!this.isUnstagedList($(el).closest('ol'))) { return false }
    
    this.stagedPanel.find('ol').append(el);
    el.focus();
    this.updateOrder();
  }
  
  makeUnstaged(el) {
    el = ("string" !== typeof el) ? el : this.#findItemWithHeader(el);
    if (this.isUnstagedList($(el).closest('ol'))) { return false }
    
    this.unstagedPanel.find('ol').append(el);
    this.updateOrder();
    this.sortUnstagedList();
    el.focus();
  }
  
  toggleStaged(el) {
    el = ("string" !== typeof el) ? el : this.#findItemWithHeader(el);
    if (this.isUnstagedList($(el).closest('ol'))) {
      this.makeStaged(el);
    } else {
      this.makeUnstaged(el);
    }
  }

  isUnstagedList(el) {
    return el && $(el).is('.item-selector--unstaged ol');
  }

  sortUnstagedList() {
    this.#sortList(this.unstagedPanel);
  }

  showDragOptions(from) {
    var dropLists = this.isUnstagedList(from) ? this.stagedPanel : this.allPanels;
    dropLists
      .css('box-shadow', '0 0 5px hsl(200, 80%, 86%)');
  }
  
  hideDragOptions() {
    this.allPanels
      .css('box-shadow', 'none');
  }

  moveUp(el) {
    this.#move(el, 'prev', 'before');
  }
  
  moveDown(el) {
    this.#move(el, 'next', 'after');
  }

  event(action, value) {
    if (value && value.toLowerCase) {value = value.toLowerCase().replace(/[^a-z0-9]+/g, '-')}
    if (value && value[0] === '-') {value = this.id + value}
    if (value === undefined) {value = this.id}
    
    analytics.event('staging-grid', action, value);
    return this;
  }

  #setupItemsSearch() {
    this.#el.on('keyup click change', '.js-item-search', function(event) {
      var grid = StagingGrid.closest(event.target);
      grid.search($(this).val().toLowerCase());
    });
  }

  #setupItemsReset() {
    this.#el.on('click', '.js-item-reset', function(event) {
      event.preventDefault();
    
      StagingGrid.closest(event.target).event('item-reset').reset();
    });
  }

  #setupItemsStageAll() {
    this.#el.on('click', '.js-item-stage-all', function(event) {
      event.preventDefault();

      StagingGrid.closest(event.target).event('item-stage-all').stageAll();
    });
  }

  #setupItemsKeyboardControl() {
    this.#el.on('keydown', '.item-selector .item', function(event) {
      var grid = StagingGrid.closest(event.target);
    
      switch (event.which) {
        case 13: // enter
          grid.toggleStaged(event.target);
          break;
        case 37: // left
          grid.makeStaged(event.target);
          break;
        case 39: // right
          grid.makeUnstaged(event.target);
          break;
        case 38: // up
          grid.moveUp(event.target);
          break;
        case 40: // down
          grid.moveDown(event.target);
          break;
      }
    });
  }

  #setupItemVisibilityToggle() {
    this.#el.on('click', '.item-group', function(event) {
      var grid = StagingGrid.closest(event.target);
      $(this).nextUntil('.item-group').filter('.item').each(function() {
        grid.toggleStaged($(this));
      });
    });
    this.#el.on('click', '.item-selector .item', function(event) {
      if (!$(event.target).is('.item, .item span')) return;
      StagingGrid.closest(event.target).toggleStaged($(event.target).closest('.item'));
    });
  }

  #setupItemSortability() {
    this.#el.find('.item-selector ol').sortable({
      cursor: 'move',
      handle: '.grip',
      opacity: .75,
      placeholder: 'sorting-placeholder',
      connectWith: '.item-selector ol',
      start: function(event, ui) {
        StagingGrid.closest(event.target).showDragOptions($(this));
      },
      over: function(event, ui) {
        var grid = StagingGrid.closest(event.target);
        var isUnstaged = grid.isUnstagedList(this);
        var wasUnstaged = grid.isUnstagedList(ui.sender);
      
        ui.placeholder.toggleClass('hidden', isUnstaged && wasUnstaged);
      
        if (isUnstaged) {
          ui.placeholder.text('hide item');
        } else if (wasUnstaged) {
          ui.placeholder.text('show item');
        } else {
          ui.placeholder.text('reorder item');
        }
      },
      stop: function(event, ui) {
        var grid = StagingGrid.closest(event.target);
        grid.hideDragOptions(ui);
        grid.sortUnstagedList();
      },
      remove: function(event, ui) {
        var grid = StagingGrid.closest(event.target);
        var wasUnstaged = grid.isUnstagedList(this);
        grid.event('staging-grid.item-' + (wasUnstaged ? 'checked' : 'unchecked'), '-' + ui.item.data('item-name'));
      },
      update: function(event, ui) {
        var grid = StagingGrid.closest(event.target);
        if (grid.isUnstagedList(this)) {return}
      
        StagingGrid.closest(event.target).updateOrder();
      }
    });
  }

  #updateLabels() {
    this.stagedPanel.find('.item').each(function(ix) {
      $(this).attr('aria-label-original') || $(this).attr('aria-label-original', $(this).attr('aria-label'));
      $(this).attr('aria-label', $(this).attr('aria-label-original') + ". Shown as item " + (ix + 1));
    });

    this.unstagedPanel.find('.item').each(function() {
      $(this).attr('aria-label-original') || $(this).attr('aria-label-original', $(this).attr('aria-label'));
      $(this).attr('aria-label', $(this).attr('aria-label-original') + ". Unstaged.");
    });
  }

  #findItemWithHeader(header) {
    return this.#el.find('.item[data-item-header="' + header + '"]');
  }

  #sortList(list) {
    this.#sortListBy(list, function(a, b) {
      // always sort zero-state to end
      if ($(a).is('.zero-state')) return 1;
      if ($(b).is('.zero-state')) return -1;

      // sort by group first
      var ixGroupA = parseInt($(a).data('item-group-index'), 10);
      var ixGroupB = parseInt($(b).data('item-group-index'), 10);
      if (ixGroupA !== ixGroupB) return ixGroupA > ixGroupB ? 1 : -1;

      // and index second
      var ixA = parseInt($(a).data('item-index'), 10);
      var ixB = parseInt($(b).data('item-index'), 10);
      ixA = isNaN(ixA) ? -1 : ixA;
      ixB = isNaN(ixB) ? -1 : ixB;
      return ixA > ixB ? 1 : -1;
    });
  }

  #sortListByDefaultOrder(list) {
    this.#sortListBy(list, function(a, b) {
      var ixA = parseInt($(a).data('item-default-index'), 10);
      var ixB = parseInt($(b).data('item-default-index'), 10);
      return ixA > ixB ? 1 : -1;
    });
  }

  #sortListBy(list, by) {
    list.find('ol li').sort(by).prependTo(list.find('ol'));
  }

  #move(el, direction, location) {
    el = ("string" !== typeof el) ? el : this.#findItemWithHeader(el);
    if (this.isUnstagedList($(el).closest('ol'))) { return false }

    var $el = $(el);
    var $direction = $el[direction]();
    if ($direction.length === 0) return;
    $direction[location]($el);
    el.focus();
    this.updateOrder();
  }
}

export default {
  setup() {
    new BusyBody({
      selector: '.staging-grid',
      added: (el) => { new StagingGrid($(el)).setup() },
    });
  }
};

window.StagingGrid = StagingGrid;
