class TitleCategoryUpdater {
  constructor(event) {
    this._$target = $(event.target);
  }
  
  update() {
    this.fetch()
      .then((json) => this.updateCategories(json));
  }
  
  fetch() {
    return $.ajax({
      url: '/api/public/titles/categories',
      data: {title_ids: this.currentTitleIds()}
    });
  }
  
  updateCategories(json) {
    var $list = this.categoriesList();
    
    $list.find('.senate-level-label').replaceWith($(json.senate_level.label));
    $list.find('.senate-level-helptip').replaceWith($(json.senate_level.helptip));
    
    $list.find('.availability-cohort-label').replaceWith($(json.availability_cohort.label));
    $list.find('.availability-cohort-helptip').replaceWith($(json.availability_cohort.helptip));
  }
  
  currentTitleIds() {
    return this._$target.val();
  }
  
  categoriesList() {
    return $(this._$target.data('dynamic-categories-target'));
  }
}

export var titleDynamicCategories = {
  setup() {
    $(document).on('change', '*[data-dynamic-categories-target]', e => {
      new TitleCategoryUpdater(e).update();
    });
  }
};
