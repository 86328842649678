import BusyBody from "../../services/busy_body";

export var analystBasicQualifications = {
  setup() {
    const basicQualificationsModalSelector = "#basic-qualifications-modal";

    new BusyBody({
      selector: basicQualificationsModalSelector,
      added: () => setupModal()
    });

    function disableSubmitButton(disabled) {
      let submitButton = document.querySelector(`${basicQualificationsModalSelector} .modal-editor--submit-button`);
      submitButton.disabled = disabled;
    }

    function setupRadioOptions() {
      let radioOptions = document.querySelectorAll(`${basicQualificationsModalSelector} .basic-qualifications-modal--decision-option`);
      radioOptions.forEach((radioOption) => {
        radioOption.onchange = () => disableSubmitButton(false);
      });
    }

    function setupModal() {
      disableSubmitButton(true);
      setupRadioOptions();
    }
  }
}
