export class TableTyrantWorkspaces {
  constructor(grid) {
    this._grid = grid;
  }
  
  get #popoverContent() { return this._grid._el.find('.workspace-picker-popover-content') }
  get #ribbon() { return this._grid._el.find('.workspace-ribbon') }
  get #actions() { return this.#ribbon.find('.actions') }
  get #cap() { return this.#ribbon.find('.cap') }
  
  get current() { return this.#ribbon.find('.current-workspace .value').text() }
  set current(newCurrent) {
    let $value = this.#ribbon.find('.current-workspace .value');
    
    // update cap
    $value.text(newCurrent);
    
    // and list
    this.links.map(function(ix, link) {
      $(link).toggleClass('is-current', newCurrent === $(link).attr('data-workspace-name'));
    });
    
    // and form
    this._grid.form.find('input[name=workspace]').val(newCurrent);
    
    return this;
  }
  
  get isDirty() { return this.#cap.is('.with-unsaved-changes') }
  set isDirty(value) {
    this.#cap.toggleClass('with-unsaved-changes', !!value);
    this.#actions.attr('data-workspace-is-dirty', !!value);
  }
  
  get isShared() { return this.#actions.attr('data-workspace-is-shared') === 'true' }
  set isShared(value) {
    this.#actions.attr('data-workspace-is-shared', !!value);
  }
  
  _workspaceList(listName) {
    return this.#ribbon.find('.' + listName + '.workspaces');
  }
  
  _urlForWorkspace(workspace, params={}) {
    return this._grid.url({workspace: workspace.name, 'filters-are': 'permanent', ...params});
  }
  
  setupPopover() {
    let $cap = this._grid._el.find('.current-workspace');
    $cap.popover('destroy');
    
    $cap.popover({
      placement: 'bottom',
      trigger: 'hover',
      html: true,
      content: () => {
        TableTyrant.Grid.closest($cap).workspaces.rehydrateLinks(this.#popoverContent);
        return this.#popoverContent.removeClass('hidden');
      },
      delay: {show: 350, hide: 350}
    }).on('hidden.popover.bs', () => {
      this.#popoverContent.addClass('hidden').insertAfter($cap);
    });
  }
  
  linkTo(workspace) {
    return $("<a class='iconic-link workspace js-workspace-link'>")
      .toggleClass('is-current', workspace.name === this.current)
      .text(workspace.name)
      .prepend(Components.helptip('This is the current workspace', {icon: 'angle-right current-signifier'}))
      .attr({
        'href': this._urlForWorkspace(workspace, {gimme: null}),
        'data-href': this._urlForWorkspace(workspace),
        'data-analytics': 'table-tyrant.use-workspace',
        'data-analytics-id': this._grid.id,
        'data-workspace-id': workspace.id,
        'data-workspace-name': workspace.name
      });
  }
  
  deleteLinkTo(workspace) {
    return $("<a class='iconic-link delete-workspace js-delete-workspace'>")
      .append(Components.iconic('trash'))
      .append('Delete')
      .append($("<span class='sr-only'>").text('my "' + workspace.name + '" workspace'))
      .attr({
        'href': workspace.urls.meta,
        'data-remote': true,
        'data-method': 'DELETE',
        'data-analytics': 'table-tyrant.use-workspace',
        'data-analytics-id': this._grid.id,
        
        'data-confirm': 'Are you sure you want to delete your "' + workspace.name + '" workspace? This action cannot be undone.',
        'data-confirm-title': 'Really delete?',
        'data-confirm-button': 'Yes, delete',
        'data-confirm-button-class': 'btn-danger',
        
        'data-workspace-id': workspace.id,
        'data-workspace-name': workspace.name
      });
  }
  
  rehydrateLinks($in) {
    var workspaces = this;
    $in.find('*[data-workspace-info]').each(function() {
      var $this = $(this);
      var workspaceInfo = JSON.parse($this.attr('data-workspace-info'));
      var rehydrator = $this.attr('data-rehydrator') || 'linkTo';
      var link = workspaces[rehydrator](workspaceInfo);
      $this.replaceWith(link);
    });
    return this;
  }
  
  workspaceHasChanged(newName, workspaces) {
    this.current = newName;
    this.isDirty = false;
    this.isShared = false;
    this.updateSavedWorkspaces(workspaces)
    this.updateActions(newName, {glow: 'success'});
    this._grid.updateUrl(this.linkForCurrentWorkspace.attr('href'));
  }
  
  updateSavedWorkspaces(workspaces) {
    var list = this._workspaceList('saved');
    list.empty();
    
    var self = this;
    $.each(workspaces, function(ix, workspace) {
      $('<li>')
        .append(self.linkTo(workspace))
        .append(self.deleteLinkTo(workspace))
        .appendTo(list);
    });
    this.checkForZeroState();
    return this;
  }
  
  get links() {
    this.rehydrateLinks(this.#popoverContent);
    return this.#popoverContent.find('.workspace[data-workspace-name]');
  }
  
  get linksForSavedWorkspaces() {
    return this.links.filter(".saved.workspaces a");
  }
  
  get linkForDefaultWorkspace() {
    return this.links.filter(".shared.workspaces a").first();
  }
  
  get linkForCurrentWorkspace() {
    return this.linkForWorkspaceNamed(this.current);
  }
  
  linkForWorkspaceNamed(name) {
    return this.links.filter('*[data-workspace-name="' + name + '"]');
  }
  
  hidePopover() {
    // it'd be nice if we could just hide it using the Bootstrap API, but that doesn't work until after the modal appears
    // so instead we push `hidden` into the classes until it hides normally, then remove it
    
    var $popover = this.#popoverContent.closest('.workspace-ribbon .popover');
    $popover.addClass('hidden');
    
    this.#cap.popover('hide').one('hidden.popover.bs', function() {
      $popover.removeClass('hidden');
    });
  }
  
  checkForZeroState() {
    var $pc = this.#popoverContent;
    $pc
      .find('.hidden-if-no-saved-workspaces')
      .toggleClass('hidden', this.linksForSavedWorkspaces.length === 0 && $pc.find('*[data-workspace-info]').length === 0);
    return this;
  }
  
  updateActions(newName, options) {
    options = options || {};
    
    var nameIs = this._nameIs(newName);
    var isDirty = this.isDirty;
    var isShared = this.isShared;
    
    this._flashWorkspaceWithName(newName, options.glow);
    
    var $content = this.#popoverContent;
    $content.find('.duplicate-name-warning')
      .toggleClass('hidden', isDirty || !nameIs.present || nameIs.unique || (nameIs.current && !isDirty));
    $content.find('.unsaved-changes-warning')
      .toggleClass('hidden', !isDirty);
    $content.find('.save-changes')
      .toggleClass('hidden', isShared)
      .prop('disabled', !nameIs.valid && !isDirty)
      .text(nameIs.valid ? (isDirty ? 'Rename and save changes' : 'Rename') : 'Save changes');
    $content.find('.save-new')
      .toggleClass('hidden', false)
      .toggleClass('btn-primary', isShared)
      .prop('disabled', !nameIs.valid);
    return this;
  }
  
  _flashWorkspaceWithName(name, glow) {
    var link = this.linkForWorkspaceNamed(name) || name;
    
    if (this.#popoverContent.closest('.popover').length > 0) {
      new Glow(link.closest('li'), '', glow || 'danger').animate();
    } // else we're not in a popover yet, so no need to animate
  }
  
  _nameIs(newName) {
    var linkToExistingWorkspace = this.linkForWorkspaceNamed(newName);
    var nameIs = {
      unique: linkToExistingWorkspace.length === 0,
      present: newName.length > 0,
      current: linkToExistingWorkspace.is('.is-current')
    };
    nameIs.valid = nameIs.unique && nameIs.present;
    return nameIs;
  }
  
  static closest(from) {
    return TableTyrant.Grid.closest(from).workspaces;
  }
  
  static updateActions($target) {
    $target.each(function() {
      TableTyrant.Workspaces.closest(this).updateActions($(this).val());
    });
  }
  
  static checkForZeroState() {
    $('.table-tyrant .workspace-ribbon').each(function() {
      TableTyrant.Workspaces.closest(this).checkForZeroState();
    });
  }
}
