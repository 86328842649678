export default class MacroValidation {
  constructor(el) {
    this.el = el;
  }
  
  get textarea() { return this.el.querySelector('textarea, input') }
  get text() { return this.textarea.value }
  get handleMatches() { return Array.from(this.text.matchAll(/(\{.*?\})/g)) }
  
  get macroLinksEl() { return this.el.nextElementSibling }
  get macroLinkEls() { return Array.from(this.macroLinksEl?.querySelectorAll('a[data-handle]') || []) }
  get handlesSupported() { return this.macroLinkEls.map(l => l.dataset.handle) }
  
  get errorArea() { return new DynamicErrorArea(this.el) }
  set errors(value) {
    this.errorArea.fill({field_errors: {'form-group---with-macro-links': value}});
  }
  
  selectHandle(atIndex, string) {
    this.textarea.selectionStart = atIndex;
    this.textarea.selectionEnd = atIndex + string.length;
  }
  
  check() {
    let expected = this.handlesSupported;
    let unexpected = this.handleMatches.filter(hm => !expected.includes(hm[0]));
    
    if (unexpected.length == 0) {
      this.errors = [];
    } else {
      this.errors = unexpected.map(handleMatch => Components.tag(
        'span',
        {class: 'macro-link-error'},
        `has unrecognized variable ${handleMatch[0]}. Click here to edit.`,
        el => el.addEventListener('click', event => {
          this.selectHandle(handleMatch.index, handleMatch[0]);
        })
      ));
    }
  }
  
  // if we check immediately, it causes a fun bug
  // while focus is in the textarea, you write a bad handle
  // you click the error message that appears, which should highlight the probelm
  // but the defocus refreshes the errors, so your click hits the background element and nothing happens
  // 100ms is quick enough to feel responsive but slow enough to avoid the bug 
  queueCheck() {
    setTimeout(() => this.check(), 100);
  }
  
  watch() {
    this.textarea.addEventListener('input', event => this.queueCheck());
    this.textarea.addEventListener('change', event => this.queueCheck());
  }
  
  static setup() {
    new BusyBody({
      selector: '.form-group---with-macro-links',
      added: (el) => { new MacroValidation(el).watch() },
    });
  }
}
