export var formShowPassword = {
  setup() {
    var showPassword = this;

    $(document).on('click', '#show-password', function(event) {
      showPassword.toggle();
    });
  },

  toggle() {
    if ($('#show-password:checkbox:checked').length > 0) {
      $(":password").attr('type', 'text');
    } else {
      $(":text").attr('type', 'password');
    }
  }
};
