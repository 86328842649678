import Translations from '../../services/translations';

export var adminUserRoleTableTyrant = {
  async setup() {
    let i18n = await Translations.create(['admin', 'campus_ingested_at_warning']);
    let warningBox = Components.tag("div");
    let toBeDeleted = Components.tag("ul", {class: 'to-be-deleted'});

    $(document).on('selection-changed', event => {
      $('.delete-selected').each(function() {
        let removeButton = $(this).find('button');
        let importedToBeDeleted = $("<ul class='to-be-deleted imported'>");

        toBeDeleted.innerHTML = '';
        $('.delete-selected').selectedCollector().checkedCheckboxes.forEach(function(checkbox) {
          let imported = checkbox.dataset.lastImported;
          let $li = $('<li>').text(`${checkbox.dataset.user} - ${checkbox.dataset.role} - ${checkbox.dataset.resource} (${checkbox.dataset.resourceType}) `);
          if (imported) {
            $li.append($('<div class="last-imported">').text(`Last imported ${checkbox.dataset.lastImported}`));
          }
          $li.appendTo(imported ? importedToBeDeleted : toBeDeleted);
        });

        warningBox = Components.tag('div');
        if (importedToBeDeleted.children().length > 0) {
          warningBox = Components.alert(
            'warning', {
              icon: 'upload',
              title: i18n.t('admin.users.table_tyrant.bulk.confirm.warning.title'),
              text: i18n.t('admin.users.table_tyrant.bulk.confirm.warning.text', {campus_specific_warning: i18n.t('campus_ingested_at_warning')}),
              class: 'imported-warning',
            }
          );

          importedToBeDeleted.appendTo(warningBox.querySelector('.text'));
        }
      });
    });
    
    $(document).on('show.bs.modal', '#ujs-confirmation.delete-user-roles', function() {
      let modalBody = $(this).find('.modal-body');
      modalBody.append(toBeDeleted);
      modalBody.append(warningBox);
    });
  }
};
