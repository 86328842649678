import {Picker} from 'pickers/picker';

class ReplacementReportPicker extends Picker {
  get strings() { return {
    ...super.strings,
    placeholder: 'Choose a new report',
  }}
  
  templateSelection(object) {
    return object.element?.getAttribute('name') || "Choose a new report";
  }
  
  templateResult(object) {
    var el = $(object.element);
    var known = el.attr('qualified_applicants');
    
    if (known) {
      return $('<div>')
        .append($('<span>').text(el.attr('created_at')))
        .append($('<span class="metadata response-rate">').text(el.attr('percentage')))
        .append($('<div class="metadata applicant-counts">').text(
          known + " applicant" + (known == "1" ? '' : 's') + "; " +
          el.attr('shortlisted_applicant_count') + " on shortlist"));
    } else {
      return $('<div>')
        .append($('<span>').text(el.attr('name')))
        .append($('<div class="metadata">').text(
          el.attr('updated_at')));
    }
  }
}

export default {
  setup() {
    ReplacementReportPicker.watch('select.replacement-report-picker');
  }
};
